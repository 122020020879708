import React from 'react';
import { Feature } from '../../components';
import './features.css';

const featuresData = [
	{
		title: 'Archelon genomför villkorat omvänt förvärv med IT-leverantören Qlosr',
		date: '2021-04-13',
		text: [
			'Archelon har ingått ett avtal om att förvärva samtliga aktier i Qlosr AB mot en köpeskilling om 190 MSEK.',
			'Affären med Qlosr måste godkännas av en majoritet av Archelons aktieägare på årsstämman den 22 april.'
		]
	},
	{
		title: 'Qlosr har tecknat ett fyraårigt avtal med Doktor 24',
		date: '2021-04-20',
		text: [
			'Qlosr meddelar idag att bolaget ingått nytt prenumerationsbaserat avtal med Doktor 24 för IT och infrastruktur.',
			'"Vi har tecknat ett fyraårigt långt prenumerationsbaserat avtal med Doktor 24. Avtalsvärdet ökar kraftfullt och kan snart uppgå till 4-6 % av vår omsättning per år" säger Qlosrs vd Jonas Norberg.'
		]
	},
	{
		title: 'Qlosr har tecknat en avsiktsförklaring om förvärv av Vibration IT',
		date: '2021-04-28',
		text: [
			'Qlosr meddelar idag att bolaget tecknat en avsiktsförklaring i det Malmöbaserade IT-bolaget Vibration IT om ett förvärv av samtliga aktier.',
			'"Förutsatt att förvärvet av samtliga aktier i Vibration IT genomförs stärker Qlosr AB sin verksamhet i Skåne för att komma närmare befintliga kunder och skapa möjlighet för Vibration ITs kunder att ta del av Qlosr ABs paketerade tjänster. Lokala ledningen kommer fortsatt att vara kvar i verksamheten och nya rekryteringar kommer att göras för att möjliggöra tillväxten" säger Qlosrs vd Jonas Norberg"'
		]
	},
	{
		title: 'Qlosrs vd: Vi ska fortsätta med det vi har gjort men i större skala',
		date: '2021-05-11',
		text: [
			'IT-lösningsbolaget har för avsikt att växa med minst 20 procent per år över en konjunkturcykel. Målet är att omsätta minst 350 miljoner kronor inom tre år.',
			'- Det är ett rimligt mål som vi börjar med. När vi väl befinner oss i börsmiljön kan det förstås hända saker och inträffa förvärvsmöjligheter som gör att det blir nya målsättningar, säger Qlosrs vd Jonas Norberg i en intervju.',
			'- Vårt case bygger inte på att vi ska hitta på något nytt, utan vi ska fortsätta göra det vi har gjort men i lite större skala.',
			'Enligt vd:n omsatte man strax över 170 miljoner kronor på rullande tolv månader, att jämföra med 99,4 miljoner under 2018 eller 29,2 miljoner år 2015.'
		]
	},
	{
		title: 'Qlosr Holding AB ställer ut en köpoption till blivande styrelseledamot',
		date: '2021-05-12',
		text: [
			'Qlosr Holding AB ställer ut en köpoption till Bengt Engström med rätt att förvärva 10 000 000 B-aktier i Bolaget. Utnyttjande av köpoptionen kan ske från och med den 3 januari 2022 till och med den 25 maj 2024. Lösenpriset per aktie i Bolaget är 10 öre.',
			'Bengt Engström sitter för närvarande i styrelsen för bland annat Bure, men har också gedigen erfarenhet av att leda och utveckla IT-bolag, framförallt som Nordenchef för Fujitsu, men också som styrelseledamot i Advania, Prevas och Partnertech.'
		]
	},
	{
		title: 'Nya styrelseledamöter föreslås i Qlosr och får också utställda köpoptioner',
		date: '2021-05-21',
		text: [
			'Det föreslås nu att Michael Englund och David Karlsson väljs in i styrelsen för vid årsstämman den 25 maj. Duon erbjuds att förvärva köpoptioner.',
			'Köpoptionen ger båda två rätt att förvärva 10 miljoner B-aktier var till priset av 10 öre per aktie. Optionen går att utnyttja mellan den 3 januari 2022 fram till och med den 25 maj 2024.'
		]
	},
	{
		title: 'Qlosr ställer ut köpoptioner till blivande ledning',
		date: '2021-05-26',
		text: [
			'Det handlar om köpoptioner med rätt att totalt köpa 22,5 miljoner B-aktier. Optionerna är utställda till fyra personer där finanschefen Anders Jäderling har rätt att köpa flest, motsvarande 10 miljoner aktier.',
			'Därefter följer Martin Öster (Future Manager) och Baran Demir (Operations Manager) med rätt att köpa 5 miljoner aktier. Sist ut är Jenny Cintra (PMO Manager) med rätt att köpa 2,5 miljoner aktier.',
			'Optionerna kan utnyttjas från och med den 3 januari 2022 fram till och med den 25 maj 2024. Lösenpriset är 10 öre per aktie.'
		]
	},
	{
		title: 'Qlosr godkänns för notering på Nordic SME',
		date: '2021-06-11',
		text: [
			'NGM-börsen har godkänt Qlosr Groups omnoteringsprocess på Nordic SME. Aktierna i Qlosr Group därför kommer att återföras till ordinarie lista.',
			'Bakgrunden är att dåvarande Archelon i april ingick avtal om omvänt förvärv av Qlosr som övertog gamla Archelons börsplats.'
		]
	},
	{
		title: 'Qlosr Group AB ingår avtal om förvärv av Vibration IT',
		date: '2021-06-11',
		text: [
			'Qlosr Group AB meddelar idag att bolaget har ingått avtal om förvärv av samtliga aktier i det Malmöbaserade IT bolaget Vibration IT AB enligt den avsiktsförklaring som tecknats med Vibration IT Holding AB och kommunicerats till marknaden 2021-04-28.',
			'Affären är av strategisk karaktär då skånemarknaden sedan tidigare är obearbetad från Qlosr Groups sida och adderar drygt 5,2 Mkr i försäljning. Förvärvet är kritiskt för att Qlosr Group ska kunna bearbeta skånemarkaden på ett effektivt vis då intresset för bolagets prenumerationstjänster är stort. Parterna har enats om att köpeskillingen för aktierna ska erläggas dels kontant, dels via nyemitterade aktier till teckningskursen 0,06 sek/aktie vid tillträdet. Därtill kan en tilläggsköpeskilling komma att erläggas i form av ytterligare nyemitterade aktier till en teckningskurs på 0,08 sek/aktie.',
			'Parterna har vidare valt att inte offentliggöra köpeskillingen. Tillträde av aktierna i Vibration IT förväntas ske den 1 juli 2021.'
		]
	},
	{
		title: 'Qlosr Group tecknar avsiktsförklaring avseende förvärv av Bmore IT',
		date: '2021-06-22',
		text: [
			'Qlosr Group AB meddelar idag att bolaget har tecknat en avsiktsförklaring om att förvärva samtliga aktier i det Göteborgsbaserade IT-bolaget Bmore IT AB samt med avsikt att i samband med detta också förvärva 100 % av aktierna i systerbolaget IT Finansiering i Väst AB.',
			'Bmore IT och IT Finansiering förväntas omsätta cirka 190 Mkr under innevarande bokföringsår och förväntas generera ett rörelseresultat om cirka 11 Mkr. Förvärvets köpeskilling förväntas uppgå till cirka 80 miljoner kr där betalning föreslås delas upp i cirka 60 % aktier och cirka 40 % i likvida medel. Affären avses enligt planerna att slutföras under Q3 2021.',
			'Ett förvärv av Bmore IT AB och IT Finansiering skulle stärka Qlosr Groups möjlighet att kraftigt växa sina prenumerationsintäkter inom den publika sektorn i hela landet. Ett förvärv skulle även innebära att Qlosr Group kan accelerera bearbetningen av SME-marknaden och friskolor i västra Sverige och växa prenumerationsaffären i Regionen.'
		]
	},
	{
		title: 'Framtidschef ökar sitt aktieinnehav i Qlosr Group',
		date: '2021-06-24',
		text: [
			'Martin Öster har den 22 juni köpt 1,2 miljoner B-aktier i teknikbolaget Qlosr Group där han är framtidschef. Aktierna köptes till kursen 7 öre per aktie, en affär på 84 000 kronor. Det framgår av Finansinspektionens insynsregister.'
		]
	},
	{
		title: 'Qlosr Group AB tillträder aktierna i Vibration IT ',
		date: '2021-07-01',
		text: [
			'I enlighet med tidigare pressmeddelande den 11 juni 2021 har Qlosr Group AB nu tillträtt aktierna i Vibration IT AB.',
			'Mot bakgrund av att del av köpeskillingen erläggs genom nyemitterade aktier i Qlosr Group, har styrelsen med stöd av årsstämmans bemyndigande beslutat om att emittera 12 500 000 nya B-aktier i Qlosr Group. Teckningskursen uppgår till 0,06 per aktie i enlighet med aktieöverlåtelseavtalet.',
			'Ägarna till Vibration IT Holding AB har förbundit sig att inte att inte avyttra dessa aktier i Qlosr Group fram till den 31 december 2021.'
		]
	},
	{
		title: 'Kundnära IT-lösning ger tillväxt - Mangold Insight Analys ',
		date: '2021-07-07',
		text: [
			'Mangold inleder bevakning av IT-företaget Qlosr Group med en riktkurs på 0,13 kronor per aktie och rekommendationen köp.',
			'Den satta riktkursen motsvarar en uppsida på drygt 100 procent från var Qlosr-aktien står i nuläget. Enligt Mangold är riktkursen uppskruvad tack vare bolagets konkurrenskraftiga tjänst och återkommande intäkter från en kundbas med hög potentiell tillväxt.',
			'Andra saker som talar för bolaget är den stora marknadspotentialen, att det finns en beprövad förvärvsstrategi och att ledningen besitter stora aktieinnehav.',
			'Qlosrs operativa ledning är även bolagets största ägare. I samband med noteringen har de åtagit sig en lock-up period fram till sommaren 2022. Dessa är samma personer som byggt bolaget med en årlig tillväxt om 30-40 procent. Mangold ser positivt på att personer i ledande befattningar har samma intressen som aktieägare då detta bör leda till långsiktigt värdeskapande”, skriver Mangold.'
		]
	},
	{
		title: 'Styrelsen för Qlosr Group AB beslutar om en företrädesemission om cirka 51,6 miljoner kronor',
		date: '2021-08-13',
		text: [
			'Styrelsen för Qlosr Group AB har beslutat om en nyemission av högst 114 559 721 units bestående av B-aktier med företrädesrätt för Qlosr befintliga aktieägare (“Företrädesemissionen”). Under förutsättning att Företrädesemissionen fulltecknas tillförs Bolaget cirka 51,6 miljoner kronor före emissionskostnader. Teckningstid för Företrädesemissionen beräknas löpa under perioden 24 augusti – 7 september 2021. Bolaget har erhållit garantiåtaganden och teckningsåtaganden motsvarande cirka 80 procent av Företrädesemissionen.',
			'Teckningskursen per unit, innehållande tio (10) B-aktier, uppgår till 0,45 SEK, vilket innebär en teckningskurs om 0,045 SEK per B-aktie.',
			'Motivet till Företrädesemissionen är primärt att växa verksamheten genom förvärv, förtidslösen av utvalda finansieringsavtal och omställning av affärsverksamheten mot en ökad andel prenumerationstjänster med väsentligt ökad lönsamhet som följd.'
		]
	},
	{
		title: 'Delårsrapport',
		date: '2021-08-13',
		text: [
			'Teknikbolaget Qlosr Group redovisar ökad omsättning och en vändning till vinst under andra halvåret jämfört med samma period föregående år.',
			'Omsättningen steg 5,1 procent till 82,3 miljoner kronor (78,3).',
			'Rörelseresultatet blev 3,7 miljoner kronor (-2,1), med en rörelsemarginal på 4,5 procent.',
			'Resultatet före skatt var 3,1 miljoner kronor (-1,9).',
			'Resultatet efter skatt blev 2,3 miljoner kronor (-2,3).',
			'Likvida medel uppgick till 21,0 miljoner kronor (32,1).',
			'"Verksamhetsresultatet och prenumerationstjänsterna har ökat trots att ledningen lagt en stor del av arbetstiden på det omvända förvärvet. När börsnoteringen nu är genomförd kommer tid frigöras till att utveckla affären ytterligare", uppger Qlosr Groups vd Jonas Norberg.'
		]
	},
	{
		title: 'Finansiella mål 2024',
		date: '2021-08-13',
		text: [
			'Tillväxt: Genomsnittlig försäljningstillväxt ska uppgå till minst 20% per år över en konjunkturcykel. Tillväxten ska ske såväl organiskt som genom förvärv. Omsättningen ska inom 3år uppgå till minst 350 MKR',
			'Marginal: EBITA-marginal på minst 10% inom 3år',
			'Utdelningspolicy: Har som mål att dela ut 30% av resultatet efter skatt. Utdelningen ska vid varje tillfälle vägas mot bolagets affärsmöjligheter.'
		]
	},
	{
		title: 'Qlosr Group handlas i dag exklusive uniträtt i nyemission',
		date: '2021-08-19',
		text: [
			'Teknikbolaget Qlosr Group handlas i dag den 19 augusti exklusive uniträtt i nyemission med företrädesrätt för aktieägarna.'
		]
	},
	{
		title: 'Qlosr Groups grundare Johan Bjerhagen avgår som ordförande',
		date: '2021-08-26',
		text: [
			'Qlosr Groups grundare Johan Bjerhagen kommer att lämna som styrelseordförande för att ersättas med styrelseledamoten Bengt Engström.',
			'Bjerhagen förblir kvar som styrelseledamot och chef för företagsaffärer, M&A. Det sistnämnda vill han lägga extra krut på framöver.'
		]
	},
	{
		title: 'Qlosr Group AB meddelar att styrelse och ledning tecknar units i den pågående företrädesemissionen',
		date: '2021-08-26',
		text: [
			'Qlosr Group AB meddelar idag att delar av styrelse och ledning kommer att teckna units motsvarande cirka 2 810 000 SEK i den pågående företrädesemissionen av units bestående av B-aktier, vilken offentliggjordes den 13 augusti 2021 ("Företrädesemissionen").',
			'Johan Bjerhagen, Styrelseordförande, tecknar via bolag 1 111 111 units, motsvarande cirka 500 000 SEK.',
			'Bengt Engström, Styrelseledamot, tecknar via bolag 1 111 111 units, motsvarande cirka 500 000 SEK.',
			'Joakim Ribb, Styrelseledamot, tecknar via bolag 1 111 111 units, motsvarande cirka 500 000 SEK.',
			'Jonas Norberg, CEO, tecknar via bolag 111 111 units, motsvarande cirka 500 00 SEK.',
			'Anders Jäderling, CFO, tecknar 1 000 000 units, motsvarande 450 000 SEK.',
			'Gustav Tjernström, Head of Sales, tecknar 222 222 units, motsvarande 100 000 SEK.',
			'Baran Demir, IT Operation Manager, tecknar 555 555 units, motsvarande 250 000 SEK.',
			'Martin Öster, Future Manager, tecknar 400 000 units, motsvarande 180 000 SEK.',
			'Jörgen Lomander, Sales Manager, tecknar 555 555 units, motsvarande 250 000 SEK.',
			'Yenny Gerschman, Customer Experience Manager, tecknar 66 666 units, motsvarande 30 000 SEK.'
		]
	},
	{
		title: 'Aktieanalys på Qlosr - Analyst Group ser motiverat värde om 0,083 kronor',
		date: '2021-08-27',
		text: [
			'Qlosr är en helhetsleverantör av IT-lösningar som säljs via prenumeration vari Bolaget adresserar SME-företag av storleken 10-300 användare samt den privata skolsektorn. Genom att kunna erbjuda IT-lösningar med en funktionalitet som mindre IT-leverantörer har svårigheter att leverera och till en annan flexibilitet, kundfokus och prislapp än vad de större leverantörerna klarar av, genererar Bolagets unika positionering på marknaden mervärde åt sina kunder. Med en applicerad målmultipel om P/S 2,0x på 2021/2022 års estimerade omsättning om ca 370,6 MSEK, motiveras ett värde per aktie om 0,083 kr i ett Base scenario.',
			'Analysfirman skriver även att pandemin påskyndat digitaliseringen och att detta medfört ökad flexibilitet för bolag, men även inneburit utmaningar. Vikten av att skydda interna system och data kräver en viss kompetens som de flesta SME-företag inte besitter, något som Qlosr levererar.'
		]
	},
	{
		title: 'Qlosr Group får utökat avtal med Stockholms Stadsmission',
		date: '2021-08-31',
		text: [
			'Teknikbolaget Qlosr Group meddelar att Stockholms Stadsmission utökar sitt IT-avtal med nya prenumerationstjänster via ett avtal på fem år. Det sammanlagda avtalsvärdet för Qlosr är cirka 12 miljoner kronor.',
			'Utökningen gör att Stockholms Stadsmission och Stadsmissionens Skolstiftelse stärker sin position som en av Qlosr Groups största kunder.',
			'Stadsmissionens Skolstiftelse driver gymnasieskola, folkhögskola och yrkeshögskola med drygt 3.000 elever i Mälardalen.'
		]
	},
	{
		title: 'Fler prenumerationsintäkter ger lönsamhet för Qlosr - Mangold ser motiverat värde om 0,083 kronor',
		date: '2021-09-06',
		text: [
			'Teknikbolaget Qlosr Group har ställt om omsättningen till en högre andel återkommande prenumerationsintäkter, något som ökat lönsamheten. Det skriver Mangold i en uppdragsanalys.',
			'Bolaget genomför en nyemission om knappt 52 miljoner kronor före emissionskostnader. Pengarna ska användas för att förbättra kapitalstrukturen samt att finansiera framtida förvärv. Mangold skriver att kapitaltillskottet blir extra intressant då Qlosr tidigare kommunicerat avsikten att förvärva Bmore IT, ett förvärv som hade givit en stor strategisk potential. Utspädningseffekten blir drygt 13 procent.',
			'Analysfirman uppdaterar sin riktkurs till 0,101 kronor på 12 månaders sikt till följd av emissionens utspädningseffekt, vilket ger en uppsida om 61 procent.'
		]
	},
	{
		title: 'Qlosr Group AB meddelar utfall av företrädesemission',
		date: '2021-09-10',
		text: [
			'Teckningsperioden löpte från den 24 augusti till den 7 september 2021. Utfallet visar att Företrädesemissionen har tecknats till cirka 41,2 MSEK vilket motsvarar en teckningsgrad om 80 procent.',
			'Utfallet visar att 33 608 552 units, motsvarande cirka 29 procent av Företrädesemissionen, tecknades med stöd av uniträtter och 17 677 061 units, motsvarande cirka 16 procent av Företrädesemissionen, tecknades utan stöd av uniträtter, vilket innebär att cirka 35 procent av Företrädesemissionens totala volym tilldelas emissionsgaranterna.'
		]
	},
	{
		title: 'Styrelseordförande köpt aktier för 0,5 MLN KR',
		date: '2021-09-14',
		text: [
			'Bengt Engström, som på tisdagen tillträdde styrelseordförandeposten i Qlosr, har köpt drygt 1.111.000 aktier i bolaget för 0,5 miljoner kronor.',
			'Förvärvet genomfördes på tisdagen till snittkursen 0:45 kronor per aktie.'
		]
	},
	{
		title: 'Företrädesemission registrerad – omvandling av BTU till B-aktier',
		date: '2021-09-28',
		text: [
			'Qlosr Group AB har genomfört en företrädesemission av units, bestående av B-aktier, om cirka 41,2 MSEK, som avslutades den 7 september 2021 (”Företrädesemissionen”). Företrädesemissionen är nu registrerad hos Bolagsverket och sista dag för handel med BTU är den 30 september 2021. Avstämningsdag är den 4 oktober 2021, varefter BTU omvandlas till B-aktier. De nyemitterade B-aktierna beräknas finnas tillgängliga på respektive aktieägares depå/vp-konto den 6 oktober 2021.'
		]
	},
	{
		title: 'Ingår avtal om förvärv av Bmore IT AB samt IT Finansiering i Väst AB och dubblerar därmed omsättningen',
		date: '2021-09-28',
		text: [
			'Teknikbolaget Qlosr Group har ingått avtal om att förvärva Bmore IT och IT Finansiering i Väst för 45 miljoner kronor, enligt den avsiktsförklaring som tecknats med bolagen 22 juni 2021.',
			'Av köpeskillingen kommer 22,5 miljoner kronor erläggas kontant och 22,5 miljoner kronor i form av cirka 265 miljoner nyemitterade aktier i Qlosr med en teckningskurs om 0,085 kronor per aktie.',
			'Förvärven är bland annat tänkta att öka Qlosr Groups möjlighet att kraftigt växa sina prenumerationsintäkter inom den publika sektorn, samt accelerera SME-marknaden och friskolor i västra Sverige.',
			'Bmore IT och IT Finansiering förväntas omsätta cirka 190 miljoner kronor under räkenskapsåret 2020/2021 (Bolagen har brutet räkenskapsår per 0831). Vidare väntas de generera ett rörelseresultat om cirka 11 miljoner.'
		]
	},
	{
		title: 'Tillträder aktierna i Bmore IT AB samt IT Finansiering i Väst AB',
		date: '2021-10-01',
		text: [
			'I enlighet med tidigare pressmeddelande den 28 september 2021 har Qlosr Group AB nu tillträtt aktierna i Bmore IT AB samt IT Finansiering i Väst AB.',
			'Mot bakgrund av att del av köpeskillingen erläggs genom nyemitterade aktier i Qlosr Group, har styrelsen beslutat om att emittera 264 705 882 nya B-aktier i Qlosr Group till säljarna av Bmore IT samt IT Finansiering. Teckningskursen uppgår till 0,085 kronor per aktie i enlighet med aktieöverlåtelseavtalet. De nyemitterade aktierna är föremål för sedvanliga överlåtelserestriktioner under en period om tolv månader från tillträdet.'
		]
	},
	{
		title:
			'Bmore IT AB erhåller tilldelningsbeslut med Adda Inköpscentral AB avseende avtal om digitala enheter samt tjänster för arbetsplatser och skola',
		date: '2021-10-10',
		text: [
			'Qlor Group nyförvärvade bolag Bmore IT har tillsammans med fem andra leverantörer tilldelats Adda Inköpscentral ramavtal avseende digitala enheter för arbetsplats och skola.',
			'Ramavtalets takvolym är 5,3 miljarder kronor, men Inköpscentralen uppskattar värdet till 1 miljard kronor för de ramavtal som omfattas av upphandlingen. Ordervärdet bedöms tillföra Qlosr Group en omsättning om cirka 160 miljoner kronor under en fyraårsperiod varav cirka 35 procent i prenumerationsintäkter.',
			'Avtalsspärr föreligger i detta upphandlingsärende till och med den 18 oktober 2021.'
		]
	},
	{
		title: 'Qlosr Group höjer de finansiella målen för 2024',
		date: '2021-10-15',
		text: [
			'Teknikbolaget Qlosr har sedan noteringen utvecklats i en högre takt än tidigare planerat varför man väljer att uppjustera de finansiella målen för 2024.',
			'De nya målen är en omsättning på 1 miljard kronor (tidigare 350 miljoner), och en ebita-marginal på 10-12 procent (tidigare 10 procent). Omsättningsmålet ska uppnås genom en kombination av förvärv och organisk tillväxt.',
			'Därutöver siktar Qlosr på att prenumerationsaffären ska stå för 60 procent av omsättningen och generera en bruttomarginal på 55 procent år 2024.'
		]
	},
	{
		title: 'Ska etablera dotterbolag i Danmark',
		date: '2021-10-15',
		text: [
			'Qlosr aviserar en nordisk satsning med planer på etablering av dotterbolag i Danmark.',
			'Den nordiska satsningen dras den igång mot bakgrund av att ett antal nya kunder önskat en nordisk leverans av centralt paketerade prenumerationstjänster. Det nya danska dotterbolaget med kontor i Köpenhamn är planerat att etableras till första kvartalet 2022.'
		]
	},
	{
		title:
			'Spärrtiden i tilldelningsbeslutet för Bmore IT AB från Adda Inköpscentral AB har löpt ut och ett 48 månaders avtal med start 1 nov skall därmed tecknas mellan parterna',
		date: '2021-10-19',
		text: [
			'Teknikbolaget Qlosr Groups nyförvärvade dotterbolag Bmore IT fick som meddelats tidigare i oktober ett tilldelningsbeslut om att tillsammans med fem andra leverantörer teckna avtal med Adda Inköpscentral. Avtalsspärr förelåg då i tilldelningen till och med den 18 oktober. Eftersom ingen ansökan om överprövan inkommit till Inköpscentralen inom tidsspärrens förfallodag innebär det att ett 48 månaders långt avtal med start den 1 november ska tecknas mellan Bmore IT och Inköpscentralen.',
			'Ramavtalets takvolym var 5,3 miljarder kronor och Inköpscentralen uppskattar värdet till 1 miljard kronor för de ramavtal som omfattades av upphandlingen. Ordervärdet bedöms tillföra Qlosr Group en omsättning om 160 miljoner kronor under en fyraårsperiod varav 35 procent i prenumerationsintäkter.',
			'“Att spärrtiden löpt ut och att tilldelningsbeslutet från Adda Inköpscentral nu leder till ett faktiskt 48 månaders avtal med start redan per 1 nov är goda nyheter och en viktig pusselbit i vårt arbete kring att nå våra nya Finansiella mål vi kommunicerade till marknaden i pressmeddelande den 15 oktober”, säger Qlosr Groups VD Jonas Norberg.'
		]
	},
	{
		title: 'Qlosr tar sikte även på den offentliga sektorn',
		date: '2021-10-29',
		text: [
			'Teknikbolaget Qlosr Group har haft en händelserik period sedan det omvända förvärvet av Archelon i somras. Sedan dess har bolaget genomfört tre förvärv och gjort en signifikant upprevidering av sina finansiella mål.',
			'Efter det omvända förvärvet med Archelon uppdaterade man sina finansiella mål till att omsätta 350 miljoner kronor 2024 och ha en rörelsemarginal om 10 procent samt att prenumerationsbaserade intäkter skulle ha en 55-procentig bruttomarginal.',
			'"Sedan det omvända förvärvet har vi gjort tre förvärv, som gör att vi landar på en omsättning på 375 miljoner kronor redan 2022. Nu har vi höjt dessa mål till att omsätta 1 miljard 2024 och ha en rörelsemarginal om 10-12 procent. Det bottnar i att den första tiden har gått bra och att vi ser att möjligheterna att fortsätta den strategi som vi hade hoppats på skulle fungera verkligen fungerar."'
		]
	},
	{
		title: 'Qlosr Group AB offentliggör avsikt att genomföra listbyte till Nasdaq First North Growth Market',
		date: '2021-11-25',
		text: [
			'Qlosr Group offentliggör idag avsikten att genomföra ett listbyte från NGM Nordic SME till Nasdaq First North Growth Market under första halvåret 2022. Styrelsen för Qlosr Group gör en sammantagen bedömning att en listning av Bolagets aktier vid Nasdaq First North Growth Market medför bättre förutsättningar för framtida värdeskapande för Bolagets aktieägare. Detta bedöms ske genom en förväntad förbättrad likviditet i Bolagets värdepapper och ett ökat intresse för verksamheten och Bolaget från analytiker, allmänheten, institutionella investerare samt andra intressenter. Listningen vid Nasdaq First North Growth Market bedöms vidare utgöra en kvalitetsstämpel mot potentiella kunder, samarbetspartners och leverantörer.',
			'- Listningen på Nasdaq First North Growth Market är ett naturligt steg i bolagets utveckling. Nasdaq är ett globalt och välkänt varumärke och en listning vid Nasdaq First North Growth Market är en kvalitetsstämpel. Vi bedömer att detta kommer att skapa bättre förutsättningar för ökad synlighet på aktiemarknaden och ett ökat intresse från såväl industriella som institutionella investerare och kunder, säger Bengt Engström, styrelseordförande Qlosr Group.'
		]
	},
	{
		title: 'Qlosr Group AB ingår avtal om förvärv av IT-bolagen Rg19 i Norden AB och SBL Data AB',
		date: '2021-11-25',
		text: [
			'Qlosr har ingått avtal om förvärv av det Stockholmsbaserade bolaget RG19 i Norden och det Skarabaserade bolaget SBL Data.',
			'Affärerna väntas innebära att Qlosr passerar en halv miljard kronor i omsättning 2022 med ett justerat rörelseresultat före av- och nedskrivningar (ebitda) på 6-8 procent, enligt ett pressmeddelande.',
			'En fast initial köpeskilling på drygt 149 miljoner kronor erläggs för RG19 och vid tillträdet, planerat till den 1 december i år, erläggs 50 miljoner kronor kontant och 85 miljoner i form av 1 miljard nyemitterade aktier i Qlosr, med en teckningskurs på 0:085 kronor per aktie.',
			'För SBL data ska en fast initial köpeskilling om 21 miljoner kronor erläggas vid tillträdet som är planerat till den 4 januari 2022, varav 12,5 miljoner kontant och 8,5 miljoner i form av 100 miljoner nyemitterade aktier i Qlosr med en teckningskurs på 0:085 kronor.',
			'Säljarna är bundna av lock up 9 månader efter erhållandet av de nyemitterade aktierna.'
		]
	},
	{
		title: 'Gör riktad emission',
		date: '2021-11-25',
		text: [
			'Teckningskursen i den Riktade emissionen har fastställts genom ett s.k. accelererat bookbuilding-förfarande som arrangerats av Erik Penser Bank och uppgår till 0,04 kronor per aktie, motsvarande en rabatt om cirka 11 procent mot den 10-dagars volymvägda kursen för Bolagets aktie på NGM Nordic SME till och med den 25 november 2021.',
			'Tecknare i den Riktade emissionen utgörs av ett antal kvalificerade och välrenommerade investerare, däribland Modelio Equity.',
			'Styrelsen för Qlosr Group har vidare beslutat om en riktad emission om sammanlagt 200 000 000 aktier till teckningskurs 0,04 kronor per aktie, motsvarande sammanlagt 8 Mkr, till Qlosr Holding AB, Qlosr Group´s styrelseordförande Bengt Engström (via egenägt bolag), grundaren och styrelseledamoten, Johan Bjerhagen (via egenägt bolag) samt Qlosr Group’s koncern-VD Jonas Norberg (via egenägt bolag).',
			'Lock up-perioden för Qlosr Holding AB förlängs från den 9 juni 2022 till och med den 9 december 2022. För Modelio Equity, Lodet AB och Andreas Hoffmann förlängs lock up-perioden från den 9 december 2021 till och med den 9 juni 2022.'
		]
	},
	{
		title: 'Qlosr Group har genomfört certifiering som ska ge ökade prenumerationsintäkter',
		date: '2021-12-13',
		text: [
			'Certifikatet, som är utfärdat av certifieringsorganet Scandinavian Business Certification, innebär att bolaget nu kan svara på upphandlingar där det finns krav för certifiering inom ISO 27001.',
			'- Vi ser en ökad efterfrågan på säkerhetstjänster från våra kunder. Certifieringen av ISO 27001 är ett steg i bolagets ökade fokus på paketerade säkerhetstjänster vilket möjliggöra nya affärer med befintliga kunder och en bredare bearbetning av marknaden. Vi ser också att detta möjliggör ökade affärsmöjligheter med kunder som ställer krav på ISO 27001 vid upphandling av nya IT tjänster, säger Jonas Norberg, vd Qlosr Group.'
		]
	},
	{
		title: 'Qlosr Group påbörjar nordisk satsning - nytt dotterbolag i Danmark',
		date: '2022-02-04',
		text: [
			'Teknikbolaget Qlosr Group har påbörjat den nordiska satsning som tidigare signalerats, genom att etablera nytt dotterbolag och kontor i Köpenhamn. Det framgår av ett pressmeddelande.',
			'Verksamheten i Danmark, Qlosr Denmark, är tänkt att starta den sjunde februari och Qlosr Malmös nuvarande vd Sonja Putica har utsetts som tillförordnad vd.',
			'"Det gläder mig otroligt att vi fortsatt klarar av att leverera på vår plan och är igång med att starta nya leveranser från vår första egna enhet utanför Sverige", kommenterar Qlosr Groups vd Jonasa Norberg.'
		]
	},
	{
		title: 'Lerums kommun adderar tjänster uppgående till ca 8,5 Msek',
		date: '2022-02-09',
		text: [
			'Teknikbolaget Qlosr Group meddelar att Lerums kommun adderar paketerade prenumerationstjänster inom arbetsplats till ett värde av 8,5 miljoner kronor. Det sker inom ramen för det befintliga IT-avtal som kommunen har med Qlosr.',
			'”Vi är otroligt glada att Lerums kommun avropar fler tjänster och vi ser detta som ett tydligt kvitto på att våra paketerade prenumerationstjänster tillsammans med ett nära partnerskap är det kunden vill ha. Avropet visar också att bolagets strategi med nära partnerskap och egen producerade konfigurationstjänster med livscykelhantering skapar kundvärden som leder till fler affärer”, säger vd Jonas Norberg.'
		]
	},
	{
		title: 'Stadsmissionens Skolstiftelse avropar prenumerationstjänster till ett värde av ca 8 Msek',
		date: '2022-02-11',
		text: [
			'Stadsmissionens Skolstiftelse utökar sitt nuvarande avtal med Qlosr Group och adderar paketerade tjänster för elevklienter enligt Qlosr Groups prenumerationsmodell. Inom ramen för uppdraget kommer Qlosr Group också att återta befintlig hårdvara och hantera på ett miljöriktigt sätt enligt Bolagets hållbarhetsplan. Utökningen gör att Stadsmissionens Skolstiftelse stärker sin position som en av Qlosr Groups största kunder.',
			'Stadsmissionens Skolstiftelse, som driver gymnasieskolor, gymnasiesärskola, folkhögskola och yrkeshögskola med drygt 3 000 elever, deltagare och studenter i Mälardalen, fortsätter därmed att utöka sitt engagemang med Qlosr Group.',
			'"Detta är den fjärde utrullning av paketerade prenumerationstjänster för arbetsplats som vi gör tillsammans med Stadsmissionens Skolstiftelse och uppdraget visar återigen att Bolagets strategi med nära partnerskap och paketerade tjänsteerbjudanden skapar kundvärden som leder till fler återkommande affärer, säger Jonas Norberg VD, Qlosr Group AB"'
		]
	},
	{
		title: 'Qlosr utser ny vd för Qlosr Stockholm',
		date: '2022-02-15',
		text: [
			'Qlosr Group har utsett Robin Henrysson till ny VD för Qlosr Stockholm. Robin Henrysson kommer närmast från rollen som Director Customer Development på Visolit Sweden AB där han också ingick i den svenska ledningsgruppen.',
			'Qlosr Group har i och med sina totalt fem förvärv under andra halvåret 2021 gått in i en fas med nya mål, integrationer och korsförsäljningsmöjligheter. Jonas Norberg har tills nu axlat rollen som både Koncernchef (CEO) för Qlosr Group och VD för Qlosr Stockholm. I egenskap av endast Koncernchefsrollen (CEO) för Qlosr Group frigörs tid för Jonas Norberg som nu kan fokusera på hela koncernens omställningen av direkta intäkter till prenumerationsintäkter',
			'"Genom ett ökat fokus och frigörande av tid kommer Jonas Norberg att kunna planenligt accelerera koncernens omställning till prenumerationstjänster, samt driva strategiska initiativ mot de finansiella målen 2024", säger Bengt Engström, Styrelseordförande Qlosr Group AB.',
			'Robin Henrysson tillträder rollen som VD för Qlosr Stockholm 2022-05-02.'
		]
	},
	{
		title: 'Qlosr Group AB bokslutskommuniké 2021',
		date: '2022-02-23',
		text: [
			'Kraftigt accelererande prenumerationsintäkter, omställning av affärsmodell och fem förvärv.',
			'Teknikbolaget Qlosr Groups intäkter steg rejält under andra halvåret 2021 jämfört med samma period året innan. Nettoförlusten ökade, men sett till företagets egna justerade ebitda så var lönsamheten positiv.',
			'Nettoomsättningen steg 95,2 procent till 98,6 miljoner kronor (50,5). Den organiska tillväxten på prenumerationsintäkterna under perioden landadepå 35 procent. De totala intäkterna var 115,9 miljoner (65,4).',
			'Bruttovinsten blev 49,1 miljoner kronor (26), med en bruttomarginal på 42,4 procent (39,8).',
			'Justerat ebitda-resultat uppgick till 1,4 miljoner kronor (-2,2), med en justerad ebitda-marginal på 1,2 procent.',
			'Under första halvåret 2022 ska flera kortsiktiga lönsamhetsinitiativ genomföras. Bland annat ska en central inköpsfunktion och ett "konfigurationscenter" i Göteborg etableras.',
			'Resultatet efter skatt blev -49,9 miljoner kronor (-2,5). Kostnader för omvänt förvärv blev -11 miljoner, avskrivningar för goodwill uppgick till -6,7 och omställningskostnader var -31,7 miljoner kronor.',
			'"För 2022 ser vi att de avtalsbundna prenumerationsintäkterna skall kunna stå för ca 45 procent av den totala omsättningen som vi bedömer kan passera en halv miljard redan 2022", kommenterar vd Jonas Norberg.'
		]
	},
	{
		title: 'Qlosr Group har anställt Sophia Nilsson till Human Resource Director',
		date: '2022-02-28',
		text: [
			'Teknikbolaget Qlosr Group har anställt Sophia Nilsson till HR-chef och medlem i koncernledningen. Det framgår av ett pressmeddelande.',
			'Nilsson kommer närmast från rollen som Chief Human Resource officer på NEP Sweden där hon även satt i den svenska ledningsgruppen.',
			'Hennes initiala uppgift blir att stötta förvärvsteamet för att kvalitetssäkra onboarding och integration av personal samt vidareutveckla Qlosr Academy. Vidare ska hon även ta fram en effektiv rekryteringsstrategi.',
			'"Jag välkomnar Sophia och är mycket glad över att hon har accepterat rollen som Human Resource Director på Qlosr Group. Vi har sökt en person som har erfarenhet från kraftiga tillväxtmiljöer och som har förmågan att snabbt bygga nya strukturer. Som person är det viktigt att man passar i en entreprenördriven verksamhet och med Sophia på plats så får vi precis det", säger Jonas Norberg CEO Qlosr Group'
		]
	},
	{
		title: 'Femfaldigt förvärv förgyller framtiden - Mangold',
		date: '2022-03-02',
		text: [
			'IT-bolaget Qlosr har vuxit kraftigt sedan noteringen i somras genom ett omvänt förvärv. Tillväxten har kommit genom fem förvärv som också lett till en ökad geografisk närvaro i Sverige. Det möjliggör bearbetning av fler kunder.',
			'Vidare har Qlosr också etablerat ett dotterbolag i Danmark vilket väntas leda till ytterligare intäkter, enligt Mangold.',
			'Samtidigt har också lönsamheten stärkts genom en större andel av prenumerationsbaserade intäkter. Mangold bedömer att även framtida tillväxt kommer drivas på av prenumerationsintäkter.',
			'”Mangold sänker riktkursen från 0,101 kronor till 0,087 kronor per aktie för Qlosr på 12 månaders sikt, vilket ger en uppsida om cirka 125 procent. Riktkursen uppdateras till följd av emissionernas utspädningseffekt samt uppjusterade försäljningsprognoser. Den nominellt lägre riktkursen är högre än riktkursen som sattes i föregående analys, då antalet aktier utökats”, skriver analyshuset.'
		]
	},
	{
		title: 'Qlosr Group rekryterar Martin Lundqvist som ny Growth & Integration Director',
		date: '2022-03-21',
		text: [
			'Teknikbolaget Qlosr Group har utsett Martin Lundqvist som ny Growth & Integration Director för bolaget. Det framgår av ett pressmeddelande.',
			'Lundqvist förklaras ha 15 års erfarenhet av tjänstebaserad it-outsourcing och kommer närmast från rollen som Director Strategic Development på Visolit Sweden, där han även var med i bolagets svenska ledningsgrupp.',
			'Han kommer ha fokus på integration av genomförda och kommande förvärv samt vara en del av koncernledningen.',
			'”Jag välkomnar Martin och är mycket glad över att han har accepterat rollen som Growth & Integration Director på Qlosr Group. Vi har sökt en person som har erfarenhet från kraftiga tillväxtmiljöer och integrationer", kommenterar bolagets vd Jonas Norberg.'
		]
	},
	{
		title: 'Qlosr Group får tilldelningsbeslut från Copenhagen Malmö Port',
		date: '2022-03-29',
		text: [
			'Teknikbolaget Qlosr Group har fått ett tilldelningsbeslut från Copenhagen Malmö Port (CMP) avseende avtal om klienter- och nätverkstjänster.',
			'Avtalet omfattar tjänsteleveranser av Klienter, mobila enheter och Nätverk enligt prenumerationsmodellen där Bolaget äger helhetsansvaret för utrullningsprojekt, konfiguration, hantering och support till CMPs verksamhetsorter i Malmö och Köpenhamn.',
			'Avtalstiden är tre år från avtalsstart, med möjlighet till förlängning. Maximal avtalstid är fem år. CMP:s initiala bedömning är att affären har ett värde av 6 miljoner kronor där den exakta takvolymen varierar över tid.',
			'”Det känns fantastiskt roligt att vi kan rivstarta vår satsning på Öresundsregionen med en Strategisk affär. Satsningen på ett Köpenhamnskontor i samverkan med vårt befintliga Malmöverksamhet bygger på idén om att vi enklare kan skala upp verksamheten genom att se på Öresundsregionen som en marknad. Med CMP affären skalar vi nu upp verksamheten kraftigt i regionen och får kraft att accelerera satsningen på paketerade prenumerationstjänster i hela regionen”, säger Jonas Norberg CEO, Qlosr Group'
		]
	},
	{
		title: 'Qlosr Group föreslår en omvänd split 1:200',
		date: '2022-04-22',
		text: [
			'Aktieägarna i teknikbolaget Qlosr Group kallas till årsstämma den 25 maj. Stämman genomförs genom poströstning utan fysiskt deltagande.',
			'Styrelsen föreslår att ingen utdelning lämnas för räkenskapsåret 2021.',
			'Omval föreslås av den sittande styrelsen, bestående av styrelseordförande Bengt Engström och styrelseledamöterna Johan Bjerhagen, Michael Englund, Joakim Ribb och David Karlsson.',
			'Styrelsen förelsår att stämman beslutar om ett incitamentsprogram i form av teckningsoptioner riktat till anställda i bolaget.',
			'Styrelsen föreslår att stämman beslutar om en omvänd split 1:200, innebärande att 200 befintliga aktier läggs samman till en aktie. Antalet aktier ska vara lägst 55 miljoner och högst 220 miljoner aktier.'
		]
	},
	{
		title:
			'Qlosr Group AB lämnar uppdatering kring avsikt att genomföra notering på Nasdaq First North Growth Market',
		date: '2022-04-22',
		text: [
			'Qlosr Group offentliggjorde den 25 november 2021 avsikt att genomföra listbyte till Nasdaq First North Growth Market under första halvåret 2022.',
			'"Arbetet med listbytesprocessen fortlöper och bolagets granskningsansökan behandlas av Nasdaq. Qlosr Group avser fortsatt att noteras på Nasdaq inom den utfästa tidsramen och kommer att avge ytterligare information kring listbytesprocessen under andra kvartalet 2022", heter det.'
		]
	},
	{
		title: 'Qlosr Group AB delårsrapport första kvartalet 2022',
		date: '2022-05-11',
		text: [
			'Teknikbolaget Qlosr Group mångdubblar intäkterna under det första kvartalet.',
			'Intäkterna steg 282,6 procent till 120,9 miljoner kronor (31,6). Den organiska försäljningstillväxten var 39,1 procent. Omställningen mot prenumerationsintäkter fortsatte att accelerera kraftigt och ökade med 226 till 58 miljoner kronor, varav 21 procent var organisk tillväxt.',
			'Andelen prenumerationsintäkter av den totala omsättningen uppgick till 48, procent (53), vilket är över de 45 procent som bolaget tidigare kommunicerat för helåret 2022.',
			'Ebitda-resultat blev 7 miljoner kronor (1,3), med en ebitda-marginal på 5,8 procent (4,1). Under perioden har en kostnad tagits på 1 miljoner kronor hänförligt till avgångsvederlag i samband med effektivisering av koncernens finans och administrativa organisation.',
			'Rörelseresultatet blev -8,6 miljoner kronor (1,1). Resultatet före skatt var -11 miljoner kronor (0,9). Resultatet efter skatt blev -11 miljoner kronor (0,9).',
			'Qlosr Group upprepar sin prognos för 2022, med tillägget att bolaget skall hamna i det högre spannet av ebitda-prognosen på 6–8 procent, med en bruttomarginal överstigande 43 procent samt att bolaget med marginal skall passera en halv miljard i omsättning.',
			'Tre större effektiviseringsprojekt har även implementerats under årets första kvartal, tillsammans skall dessa projekt förbättra ebitda-resultatet under året med totalt 2,4 miljoner kronor och under nästa år med 5–7 miljoner.',
			'"Förvärvsaktiviteterna har under det första kvartalet fortsatt med oförändrad omfattning. Vi har dock valt att avvakta slutförandet av förvärv under kvartalet för att i stället fokusera på de möjligheter som de fyra förvärven från fjärde kvartalet 2021 givit oss och allt fokus har legat på att få i gång korsförsäljning, få den nya organisationen på plats samt att plocka ner de lågt hängande frukterna runt kostnadssynergierna", skriver vd Jonas Norberg i en kommentar.',
			'För de kommande 2–4 månaderna har bolaget för avsikt att slutföra nya förvärv enligt den strategi som företaget implementerat. Fokus är på lönsamma bolag med en kundbas där företaget snabbt kan starta korsförsäljning av koncernens centralt paketerade prenumerationstjänster samt driva transformation av befintlig direktförsäljning till återkommande intäkter, skriver Qlosr Group-chefen.',
			'"I de närmast kommande förvärven har vi som ambition att finansiera köpen med en kombination av kassa, skuldfinansiering samt en andel betalning i Qlosr Group-aktier", uppger Noberg.'
		]
	},
	{
		title: 'Mangold spår förbättrad lönsamhet för Qlosr och höjer riktkursen till 0,1 kronor (0,087)',
		date: '2022-05-20',
		text: [
			'Mangold ser positivt på teknikbolaget Qlosr Group och höjer lönsamhetsprognoserna för bolaget. I en färsk uppdragsanalys höjs riktkursen till 0,1 kronor (0,087) på 12 månaders sikt.',
			'Under första kvartalet i år redovisade företaget intäkter om 120,9 miljoner kronor, vilket motsvarade en tillväxt på 283 procent jämfört med samma period i fjol. Samtliga förvärv förklaras ha inkluderats i räkenskaperna, vilket bidragit till den höga tillväxttakten.',
			'Samtidigt uppgick ebitda-marginalen till 5,8 procent. Motsvarande siffra för föregående kvartal var 4,0 procent. Mangold menar även att bolagets nyliga tecknade avtal med hamnoperatören CMP visar på förmågan att kunna föra affärer i Danmark.',
			'Mangold ser också att företagets framtida tillväxt kommer drivas av en ökande andel prenumerationsintäkter. Qlosr har nyligen dessutom implementerat ett projekt som ska bättra lönsamheten framgent.',
			'Ur ovan nämnda faktorer höjer därmed Mangold företagets riktkurs, men påpekar samtidigt att en förbättrad lönsamhet måste realiseras för att riktkursen ska uppnås.'
		]
	},
	{
		title: 'Qlosr Group får villkorat godkännande för byte av marknadsplats till First North',
		date: '2022-05-20',
		text: [
			'Qlosr Group AB (publ) (”Qlosr Group” eller ”Bolaget”) har idag erhållit villkorat godkännande för upptagande till handel på Nasdaq First North Growth Market. Förutsatt att Qlosr Group formellt ansöker om godkännande och vid tidpunkten för ansökan uppfyller sedvanliga villkor och slutligt godkänns av Nasdaq bedöms första dag för handel på Nasdaq First North Growth Market infalla i början av juni 2022.',
			'Qlosr Group har, efter det omvända förvärvet av Archelon AB (publ) under 2021, varit listat på NGM Nordic SME och har under det gångna året genomgått en stark verksamhetsutveckling, stärkt av Bolagets förvärvsstrategi. Styrelsen för Qlosr Group gör en sammantagen bedömning att en listning av Bolagets aktier vid Nasdaq First North Growth Market medför bättre förutsättningar för framtida värdeskapande för Bolagets aktieägare. Detta bedöms ske genom en förväntad förbättrad likviditet i Bolagets värdepapper och ett ökat intresse för verksamheten och Bolaget från analytiker, allmänheten, institutionella investerare samt andra intressenter. Listningen vid Nasdaq First North Growth Market bedöms vidare utgöra en kvalitetsstämpel mot potentiella kunder, samarbetspartners och leverantörer.',
			'”Nasdaq är ett globalt varumärke och listningen på Nasdaq First North Growth Market är ett naturligt steg i bolagets utveckling. Detta bedöms skapa bättre förutsättningar för ökad synlighet på aktiemarknaden och ett ökat intresse från såväl industriella som institutionella investerare och kunder”, säger styrelseordförande Bengt Engström.'
		]
	},
	{
		title: 'Qlosr Group AB offentliggör bolagsbeskrivning med anledning av listbyte',
		date: '2022-05-25',
		text: [
			'Qlosr Group AB (publ) (”Qlosr Group” eller ”Bolaget”) har idag offentliggjort en bolagsbeskrivning med anledning av Bolagets planerade listbyte till Nasdaq First North Growth Market.',
			'Qlosr Group offentliggjorde den 20 maj 2022 att Bolaget erhållit villkorat godkännande från Nasdaq avseende listbytet från NGM Nordic SME till Nasdaq First North Growth Market. Nasdaqs beslut är villkorat av att inget inträffar i samband med listbytet som skulle kunna föranleda en annan bedömning av börsen. Bolaget har idag offentliggjort en bolagsbeskrivning med anledning av det avsedda listbytet som finns tillgänglig på Qlosr Groups hemsida, https://qlosrgroup.se/. Bolagsbeskrivningen bedöms inte innehålla någon tidigare icke-offentliggjord information som skulle kunna ha en väsentlig inverkan på priset på Bolagets aktie.',
			'Första dag för handel i Bolagets aktie på Nasdaq First North Growth Market är planerad till den 13 juni 2022 och är villkorad att Qlosr Group formellt ansöker om godkännande och vid tidpunkten för ansökan uppfyller sedvanliga villkor samt slutligt godkänns av Nasdaq. Handel i Bolagets aktie på NGM Nordic SME kommer att upphöra och sista dag för handel på NGM Nordic SME är planerad till den 10 juni 2022. Bolagets aktie kommer fortsatt att handlas under kortnamnet QLOSR B med nuvarande ISIN-kod SE0005594801. Inga nya aktier kommer att emitteras i samband med att Bolagets aktier upptas till handel på Nasdaq First North Growth Market. Bolagets aktieägare behöver inte vidta några åtgärder i samband med listbytet.',
			'Qlosr Group har utsett Erik Penser Bank till Bolagets Certified Adviser.'
		]
	},
	{
		title: 'Qlosr Group blir premiumpartner till WatchGuard',
		date: '2022-06-15',
		text: [
			'Teknikbolaget Qlosr Group har blivit utsedd till premiumpartner av WatchGuard Technologies som är en leverantör av cybersäkerhetstjänster.',
			'Qlosr är den enda partnern i Norden som har uppnått denna status tillsammans med endast 23 andra företag i övriga världen. Qlosr erbjuder sina kunder hela Watchguards utbud av produkter och tjänster som finns i WatchGuards molnbaserade plattform.',
			'"SBL Data och Bmore, två företag som nyligen förvärvades av Qlosr har varit WatchGuards Gold Partners i över tio år och är högt ansedda i regionen för sin tekniska och kommersiella expertis", säger Patrik Kocon, Regional Sales Director Nordics, Baltics & Ukraine på WatchGuard.',
			'"Vårt partnerskap är mycket djupt och vi har kunnat hjälpa varandra att vinna på strategisk, teknisk och kommersiell nivå under många år. Vi delar resurser för att hjälpa dessa partners att marknadsföra våra produkter och tjänster för att öka deras intäkter", säger Joakim Ribb, CTO på Qlosr.'
		]
	},
	{
		title: 'Qlosr Group handlas den 21 juni efter omvänd split 1:200',
		date: '2022-06-17',
		text: [
			'Teknikbolaget Qlosr Group beslutade på årsstämman den 25 maj om en omvänd split med villkor 1:200, innebärande att 200 befintliga aktier läggs samman till en ny aktie. Styrelsen har nu bestämt tidsplanen för omvänd split. Sista dag för handel i aktien före omvänd split är den 20 juni och aktien handlas efter omvänd split den 21 juni.'
		]
	},
	{
		title: 'Qlosr Group AB ingår avtal om förvärv av IT-bolaget EA copy AB samt inkråmet i GF Nät och Fiber AB',
		date: '2022-07-01',
		text: [
			'Qlosr Group AB meddelar idag att Bolaget har ingått avtal om att förvärva samtliga aktier i det Uppsalabaserade bolaget EA Copy AB (”EA”) samt inkråmet i det Bålstabaserade GF Nät och Fiber AB (”GF”). Förvärven finansieras främst genom egen kassa.',
			'EA är ett svenskt IT-bolag som grundades 2015 i Uppsala och erbjuder kontors- och IT-lösningar. Under de senaste två åren har EA påbörjat en framgångsrik transformation från hårdvaruförsäljning inom print till att leverera IT-tjänster till kunderna i Uppsalaregionen. EA har genom satsningen dubblerat sin omsättning på två år. Ledningen i Qlosr Group bedömer att den befintliga återkommande intäkten om 4 800 000 kronor kan dubbleras under 2023 med hjälp av Qlosr Groups paketerade prenumerationstjänster och där omsättningen i Uppsalaregionen ska överstiga 30 000 000 kronor.',
			'GF grundades 2011 och erbjuder lösningar inom nätverksinstallationer, fiberinstallationer och fibersvetsning med Stockholmsregionen som främsta arbetsområde. Förvärvet av inkråmet i GF innebär att Qlosr Group får tillgång till ökade resurser och kompetens inom området nätverk där Bolaget är inne i en stark tillväxtfas med över 50 nya leveransprojekt i orderboken för Nätverkstjänsten. Därutöver blir GF en viktig del i etableringen av den nya tjänsten ”Säker uppkoppling” som börjats marknadsföras under Q2 2022.',
			'EA och GF förväntas omsätta cirka 22 000 000 kronor med en EBITDA om cirka 3 000 000 kronor under räkenskapsåret 2022.',
			'Förvärven stärker Qlosr Groups närvaro i Uppland, som efter Stockholm, Göteborg och Malmö är Sveriges största marknad med många företag inom Bolagets affärsområden, Business, Education och Public.',
			'En fast köpeskilling om 13 000 000 kronor, minus eventuellt läckage, kommer att erläggas för EA ("Fast Köpeskilling EA"). Köpet görs enligt Locked Box per den 31 december 2021 och där EA:s förväntade befintliga kassa om 3 400 000 kronor medföljer. I samband med tillträdet, planerat till den 1 september 2022, kommer köpeskillingen att erläggas om (i) 9 000 000 kronor genom kontant betalning, och (ii) 4 000 000 kronor i form av 235 294 nyemitterade aktier i Qlosr Group, som i relation till Fast Köpeskilling EA har fastställts baserat på en teckningskurs om 17 kronor per aktie. Säljaren är bunden av en lock-up i 15 månader efter erhållandet av de nyemitterade aktierna.',
			'En fast initial köpeskilling om 600 000 kronor, minus eventuellt läckage, kommer att erläggas för GF i samband med tillträdet ("Initial Köpeskilling GF"), planerat till den 1 september 2022. 600 000 kronor kommer att erläggas genom kontant betalning.',
			'– Det känns bra att få presentera ytterligare förvärv efter ett första halvår där vi noga analyserat omvärldssituationen och lagt en strategi för att anpassa budnivåerna. Vi ser fortsatt positivt på att vi ska kunna göra ytterligare 2-3 förvärv under andra halvåret till nivåer som speglar det förändrade marknadsklimatet.',
			'Tillväxt på tillväxtstrategin ligger fast där förvärven ska ge oss tillgång till ny marknad, utökad kompetens eller kraftfull möjlighet att transformera den befintliga affären till återkommande intäkter genom Qlosr Groups paketerade prenumerationstjänster, säger Jonas Norberg, Group CEO, Qlosr Group.'
		]
	},
	{
		title: 'Qlosr Group AB anställer Urban Norberg som Group CFO',
		date: '2022-07-07',
		text: [
			'Qlosr Group anställer Urban Norberg som Group CFO för Qlosr Group. Under de senaste 10 månaderna har Urban varit inhyrd konsult på Qlosr Group och delaktig i att utveckla Bolaget från en omsättning på 150 Mkr till 500 Mkr som prognosticeras för 2022. Urbans konsultuppdrag övergår till en tillsvidareanställning med start den 1 augusti 2022.',
			'Urban Norberg har mer än 25 års erfarenhet av finans- och ekonomistyrning, compliance, M&A och att utveckla affärsmodeller och genomföra komplexa förändringsresor inom både stora globala organisationer som Sandvik, Bombardier och Scania samt riskkapitalägda bolag som Scanacon och Strängbetong.',
			'Qlosr är på en riktigt spännande tillväxtresa med stor potential. Jag ser mycket fram emot att fullt ut bli en del av detta team och fortsätta arbetet med att bygga en miljardaffär, säger Urban Norberg Group CFO på Qlosr Group',
			'CFO rollen i ett tillväxtbolag som Qlosr Group är extremt central då vi både växer organiskt, ställer om affären till mer återkommande intäkter och har en tydlig förvärvsagenda. För att det skall fungera krävs förutom bred och djup kompetens också en väldigt tydlig affärsorienterad profil. När vi tog in Urban på ett interims uppdrag under hösten 2021 såg vi stor potential. Nu 10 månader senare ser vi att Urban bidragit stort i såväl förvävsprocesser, finansieringsfrågor samt skapat en bra och tydlig uppföljningsmodell. Dessutom har Urban de där unika personliga egenskaperna som krävs för att verka i ett bolag under snabb förändring. Riktigt kul att Urban nu vill på riktigt vara med på vår resa framöver, avslutar Jonas Norberg Group CEO på Qlosr Group'
		]
	},
	{
		title: 'Qlosr Group AB kommer att byta redovisningsstandard från K3 till IFRS',
		date: '2022-08-18',
		text: [
			'Qlosr Group ska byta redovisningsstandard från K3 till IFRS, om möjligt till 2023 annars till 2024. En förstudie inleds omedelbart och Bolaget kommer att återkomma med ytterligare detaljer. Syftet är att öka transparensen och att göra det lättare att följa Bolagets underliggande utveckling.'
		]
	},
	{
		title: 'Qlosr Group AB genomför sammanslagning av Qlosr AB och Rg19 i Norden AB',
		date: '2022-08-23',
		text: [
			'Qlosr Group kommer att genomföra en sammanslagning av dotterbolaget Qlosr och det förvärvade bolaget Rg19 i Norden.',
			'Koncernen avser att ta en engångskostnad för detta om 14,5 miljoner kronor under det tredje kvartalet, varav cirka 5,5 miljoner är kassapåverkande under tredje kvartalet.',
			'Totalt ska projektet förbättra ebitda med 3 miljoner kronor under fjärde kvartalet 2022 samt cirka 12 miljoner kronor för 2023 och därefter årligen återkommande.',
			'Den sammanslagna verksamheten i Stockholm omsätter cirka 280 miljoner kronor under innevarande år.'
		]
	},
	{
		title: 'Group CEO Jonas Norberg kommenterar rapporten',
		date: '2022-08-23',
		text: [
			'Koncernens fokus på prenumerationsintäkter från långa avtal ger förbättrat EBITDA samt ett väsentligt starkare kassaflöde.',
			'Sedan börsnoteringen 2021 har vi haft ett tydligt fokus på att öka våra återkommande intäkter genom långa avtal med kunderna. Nu efter ett år konstaterar vi att de återkommande intäkterna första halvåret ökat till 116,7 MSEK jämfört med 35,8 MSEK första halvåret 2021, med en organisk tillväxt på 21,2 procent.',
			'Kassaflödet från den löpande verksamheten har samtidigt stärkts med 42,7 MSEK, vilket ökat kassan med 17,1 MSEK under första halvåret.',
			'Kontentan är att vi i en orolig omvärld är finansiellt starka, en position som vi nu skall använda för att driva fortsatt tillväxt med lönsamhet och positivt kassaflöde från verksamheten på 12 månaders basis.',
			'Vår ”Tillväxt på Tillväxt”-strategi ligger fast vilket innebär att organisationen fokuserar på att addera centrala prenumerationstjänster på sedan tidigare befintliga kunder, nyförvärvad kundbas samt kommande förvärvad kundbas.',
			'Samtidigt fortsätter vi att transformera traditionell direktaffär till återkommande intäkter i syfte att nå målet att återkommande intäkter skall överstiga 600 MSEK 2024. Strategin skall fortsatt ge stabila intäkter, stabila kassaflöden med fortsatt organisk och förvärvad tillväxt.',
			'Prognosen för 2022 kvarstår, vi skall hamna i det högre spannet av EBITDA-prognos på 6–8 procent, exklusive engångskostnader för sammanslagningen i Stockholm, med en bruttomarginal överstigande 43 procent samt att vi med marginal skall passera en halv miljard i omsättning.'
		]
	},
	{
		title:
			'Qlosr Group AB delårsrapport andra kvartalet 2022: Koncernens fokus på prenumerationsintäkter från långa avtal ger förbättrat EBITDA samt ett väsentligt starkare kassaflöde',
		date: '2022-08-23',
		text: [
			'Qlosr Group AB publicerar idag sin delårsrapport för det andra kvartalet 2022 där Bolaget levererar en kraftfull tillväxt med fortsatt stark organisk ökning av prenumerationsintäkter. Ökningen ger en förbättrad EBITDA-marginal om 6,1 procent med förbättrat kassaflöde från den löpande verksamheten efter förändring i rörelsekapitalet på 25,3 MSEK för andra kvartalet och 42,7 MSEK för årets första sex månader. EBITDA uppgår till 7,5 MSEK för Q2 2022. Periodens resultat justerat för goodwillavskrivningar ökar med 15,9 procent jämfört med årets första kvartal.',
			'Andra kvartalet APRIL – JUNI 2022:',
			'Rörelsens intäkter ökade med 280,7 procent till 123,8 MSEK (32,5), varav 27,3 procent utgjordes av organisk tillväxt.',
			'Omställningen mot prenumerationsintäkter fortsatte att accelerera kraftigt och ökade med 235,0 procent till 59,3 MSEK, varav 22,8 procent var organiskt tillväxt.',
			'Andelen prenumerationsintäkter av den totala omsättningen uppgick till 47,9 procent (54,5), vilket är över de 45,0 procent som tidigare kommunicerats som prognos för helåret 2022.',
			'Bruttomarginalen minskade till 44,0 procent (61,7), vilket dock är 1,0 procentenhet högre än prognosen på 43,0 procent för helåret 2022 samt 0,3 procentenheter högre än Q1 2022.',
			'EBITDA uppgick till 7,5 MSEK (2,7), vilket motsvarar en marginal på 6,1 procent (8,2), vilket är 0,3 procentenheter högre än Q1 2022.',
			'Under perioden har en kostnad tagits på 0,9 MSEK hänförligt till listbyte från Nordic Growth Market till Nasdaq First North Growth Market.',
			'Periodens resultat justerat för goodwillavskrivningar uppgick till 3,3 MSEK (1,4), vilket motsvarar en marginal på 2,7 procent (4,4). Resultatet justerat för goodwillavskrivningar är 15,9 procent högre jämfört med Q1 2022.',
			'Kassaflödet från den löpande verksamheten efter förändring i rörelsekapitalet uppgick till 25,3 MSEK (9,3).',
			'Kassa och bank stärktes under kvartalet med 9,2 MSEK till 59,6 MSEK.',
			'Perioden JANUARI – JUNI 2022:',
			'Rörelsens intäkter ökade med 281,8 procent till 244,8 MSEK (64,1), varav 33,1 procent utgjordes av organisk tillväxt.',
			'Omställningen mot prenumerationsintäkter fortsatte att accelerera kraftigt och ökade med 226,0 procent till 116,7 MSEK, varav 21,2 procent var organiskt tillväxt.',
			'Andelen prenumerationsintäkter av den totala omsättningen uppgick till 47,7 procent (55,9), vilket är över de 45,0 procent som tidigare kommunicerats för helåret 2022.',
			'Bruttomarginalen minskade till 43,9 procent (55,7), vilket dock är 0,9 procentenheter högre än prognosen på 43,0 procent för helåret 2022.',
			'EBITDA uppgick till 14,6 MSEK (3,9), vilket motsvarar en marginal på 6,0 procent (6,1).',
			'Under perioden har en kostnad tagits på 1,9 MSEK hänförligt till listbyte samt effektivisering av koncernens finans- och administrativa organisation.',
			'Periodens resultat justerat för goodwillavskrivningar uppgick till 6,2 MSEK (2,3), vilket motsvarar en marginal på 2,5 procent (3,7).',
			'Kassaflödet från den löpande verksamheten efter förändring i rörelsekapitalet uppgick till 42,7 MSEK (15,5).',
			'Kassa och bank stärktes under första halvåret med 17,1 MSEK till 59,6 MSEK.'
		]
	},
	{
		title: 'Qlosr Groups finanschef ökar sitt aktieinnehav',
		date: '2022-08-25',
		text: [
			'Urban Norberg har den 23 till 25 augusti genom bolaget MMS Business Management köpt 26 500 B-aktier i teknikbolaget Qlosr Group där han är finanschef. Aktierna köptes till ett genomsnittspris på 6,18 kronor per aktie, en affär på 163 500 kronor. Affären gjordes på Nasdaq Stockholm. Det framgår av Finansinspektionens insynsregister.'
		]
	},
	{
		title: 'Analysguiden tar upp bevakning av Qlosr Group - sätter riktkursen till 7,20 kronor',
		date: '2022-08-30',
		text: [
			'Analysguiden inleder bevakning av teknikbolaget Qlosr Group med riktkursen 7,20 kronor per aktie.',
			'"Även om bolaget har funnits länge, är det en helt ny koncern givet de många förvärven som har skett sedan börsnoteringen för ett år sedan. Men då bolaget lyckats växa kraftigt med lönsamhet tror vi att detta kommer att fortsätta framöver. Vi räknar med att ebitda-marginalen kan klättra strax över 13 procent i slutet av 2024. Idag framstår aktien som undervärderad av marknaden och vi inleder bevakning med en riktkurs på 7,20 kronor baserat på vår prognos för 2023p", skriver Analysguiden.',
			'Qlosr handlas idag till 5,88 kronor.'
		]
	},
	{
		title: 'Qlosr Groups huvudägare ställer ut köpoption till bolagets finanschef',
		date: '2022-08-31',
		text: [
			'Teknikbolaget Qlosr Groups finanschef Urban Norberg har förvärvat en köpoption genom sitt helägda bolag MMS Business Management. Köpoptionen har utställts av Qlosr Groups huvudägare Qlosr Holding till ett pris motsvarande bedömt marknadsvärde.',
			'Köpoptionen medför rätt att förvärva cirka 1,02 miljoner B-aktier i bolaget. Utnyttjande kan ske från och med den 1 juni 2025 till och med den 31 augusti samma år till ett lösenpris om 8,125 kronor per aktie. Det innebär inte någon utspädning för övriga aktieägare.'
		]
	},
	{
		title: 'Stärkta lönsamhetsutsikter öppnar upp för fler förvärv (Analyst Group)',
		date: '2022-08-31',
		text: [
			'Det är tydligt att Qlosr närmar våra estimat för innevarande år efter att böckerna för första halvåret 2022 har stängts. Under H1-22 har den finansiella ställningen stärkts, där Bolaget har genererat fria kassaflöden om 39 MSEK, vilket öppnar upp för fler förvärv framöver. Genom ytterligare förvärv kan Qlosr komma åt nya kundbaser i vilka Bolaget kan fortsätta att exekvera på uttalad tillväxt-på-tillväxt-strategi och därmed säkerställa en såväl stark som uthållig tillväxt framgent. Med en applicerad målmultipel om EV/EBITDA 18x på 2022 års estimerade EBITDA om 35,1 MSEK, motiveras ett värde per aktie om 10,8 kr i ett Base scenario.'
		]
	},
	{
		title: 'Stärkta lönsamhetsutsikter öppnar upp för fler förvärv (Analyst Group)',
		date: '2022-08-31',
		text: [
			'Det är tydligt att Qlosr närmar våra estimat för innevarande år efter att böckerna för första halvåret 2022 har stängts. Under H1-22 har den finansiella ställningen stärkts, där Bolaget har genererat fria kassaflöden om 39 MSEK, vilket öppnar upp för fler förvärv framöver. Genom ytterligare förvärv kan Qlosr komma åt nya kundbaser i vilka Bolaget kan fortsätta att exekvera på uttalad tillväxt-på-tillväxt-strategi och därmed säkerställa en såväl stark som uthållig tillväxt framgent. Med en applicerad målmultipel om EV/EBITDA 18x på 2022 års estimerade EBITDA om 35,1 MSEK, motiveras ett värde per aktie om 10,8 kr i ett Base scenario.'
		]
	},
	{
		title: 'Fortsatt undervärderat IT-bolag - Mangold Insight Analys',
		date: '2022-09-01',
		text: [
			'IT-bolaget Qlosrs intäkter uppgick till 123,8 (32,5) miljoner kronor under det andra kvartalet 2022, motsvarande en tillväxt om 280,7 procent. Intäkterna blev 0,6 procent lägre än vår prognos för kvartalet. EBITDA-marginalen var 6,1 procent under kvartalet vilket kan jämföras med Mangolds prognos om 5,8 procent för helåret  2022. Mangold ser positivt på lönsamhetsutvecklingen då en utökad andel prenumerationsintäkter samt kostnadseffektiviseringsåtgärder väntas förbättra marginalerna framgent.',
			'Qlosr har förstärkt sin kassa med 9,2 miljoner kronor vilket inneburit att kassan uppgick till 59,6 miljoner kronor vid utgången av det andra kvartalet. Qlosr avser använda 9,6 miljoner kronor kontant och 4 miljoner kronor i nyemitterade aktier till ett pris om 17 kronor per aktie för att förvärva EA och GF. Verksamheterna väntas omsätta 22 miljoner kronor med en EBITDA-marginal om cirka 13,6 procent under 2022. Qlosr får även resurser och kompetens inom den nyligen lanserade tjänsten ”säker uppkoppling” genom förvärvet att GF. Mangold bedömer det som positivt att Qlosr förvärvar lönsamhetsförbättrande bolag, stärker sin position i Uppsala och Stockholm samt att de framför allt betalas kontant, innebärande en utspädning om cirka 0,4 procent. ',
			'Mangold sänker den omräknade riktkursen på 19,91 kronor till 18,00 kronor per aktie för Qlosr på 12 ­månaders sikt. Riktkursen uppdateras till följd av förväntad lägre organisk tillväxt samt förväntad förbättrad lönsamhet framgent. '
		]
	},
	{
		title: 'Qlosr Group tillträder EA Copy och inkråmet i GF Nät och Fiber, beslutar om riktad nyemission',
		date: '2022-09-01',
		text: [
			'Förvärvet av EA Copy kostar 13 miljoner kronor och betalas genom nyemitterade aktier i Qlosr, totalt 235 294 nya B-aktier med teckningskursen 17 kronor per aktie. Detta är beslutat av styrelsen och är i enlighet med aktieöverlåtelseavtalet, skriver Qlosr.',
			'För inkråmet i GF Nät och Fiber uppgår den initiala köpeskillingen till 600 000 kronor och har betalts i kontanter.'
		]
	},
	{
		title: 'Qlosr Group förvärvar två Ricoh IT-partnerbolag',
		date: '2022-09-28',
		text: [
			'Qlosr Group förvärvar Uddevalla- och Skövdebaserade Attaxera IT samt Kristinehamnsbaserade Kontorsutrustning i Kristinehamn. Båda bolagen marknadsför sig som Ricoh IT Partner.',
			'De förvärvade bolagen omsatte tillsammans ungefär 52 miljoner kronor och genererade ett ebitda-resultat om cirka 13,2 miljoner kronor under bokslutsåren 2021-2022. De har tillsammans cirka 20 anställda.',
			'Köpeskillingen uppgår till 64,8 miljoner kronor och erläggs dels i kontanter och dels genom nyemitterade aktier till teckningskursen 17 kronor per aktie. Tillträde är planerat till idag.',
			'Qlosr Group har genomfört fyra stycken förvärv under det tredje kvartalet, i linje med den förvärvsplan som företaget sjösatt.',
			'- Förvärven på orterna Uddevalla, Skövde och Kristinehamn kommer att snabbt ge tydliga värden inom våra prioriterade områden. Under räkenskapsåret 2023 kan vi, exkl. välgrundade förhoppningar om synergieffekter konservativt beräknat förbättra ebitda med över 12 miljoner kronor. Förvärven ger oss en bra bas med nöjda kreditstarka kunder i långa avtal med återkommande intäkter vilka vi kan förädla och vidareutveckla med våra paketerade standardiserade prenumerationstjänster, främst inom Nät och Drift, uppger vd Jonas Norberg i en kommentar.'
		]
	},
	{
		title: 'Qlosr Group förvärvar två Ricoh IT-partnerbolag',
		date: '2022-09-28',
		text: [
			'Qlosr Group förvärvar Uddevalla- och Skövdebaserade Attaxera IT samt Kristinehamnsbaserade Kontorsutrustning i Kristinehamn. Båda bolagen marknadsför sig som Ricoh IT Partner.',
			'De förvärvade bolagen omsatte tillsammans ungefär 52 miljoner kronor och genererade ett ebitda-resultat om cirka 13,2 miljoner kronor under bokslutsåren 2021-2022. De har tillsammans cirka 20 anställda.',
			'Köpeskillingen uppgår till 64,8 miljoner kronor och erläggs dels i kontanter och dels genom nyemitterade aktier till teckningskursen 17 kronor per aktie. Tillträde är planerat till idag.',
			'Qlosr Group har genomfört fyra stycken förvärv under det tredje kvartalet, i linje med den förvärvsplan som företaget sjösatt.',
			'- Förvärven på orterna Uddevalla, Skövde och Kristinehamn kommer att snabbt ge tydliga värden inom våra prioriterade områden. Under räkenskapsåret 2023 kan vi, exkl. välgrundade förhoppningar om synergieffekter konservativt beräknat förbättra ebitda med över 12 miljoner kronor. Förvärven ger oss en bra bas med nöjda kreditstarka kunder i långa avtal med återkommande intäkter vilka vi kan förädla och vidareutveckla med våra paketerade standardiserade prenumerationstjänster, främst inom Nät och Drift, uppger vd Jonas Norberg i en kommentar.'
		]
	},
	{
		title: 'ABG tar upp bevakning av Qlosr Group',
		date: '2022-10-05',
		text: [
			'ABG Sundal Collier inleder uppdragsbevakning av teknikbolaget Qlosr Group och ser ett motiverat värde i intervallet 4-11 kronor, vilket baseras på flera värderingsmetoder.',
			'Qlosr handlas till 7,2x förväntad EV/EBITA för 2023 baserat på ABG:s estimat. Det kan jämföras med branschkollegor som handlas till 8,3-9,2x.'
		]
	},
	{
		title: 'Qlosr Group AB har tilldelats certifiering enligt Great Place to Work©',
		date: '2022-11-17',
		text: [
			'Qlosr Group AB har tilldelats certifiering enligt Great Place to Work© Institutes globala standard för vad som kännetecknar en god arbetsplats.',
			'Entusiastiska medarbetare är ett av Qlosr Groups övergripande mål. Att kontinuerligt arbeta med frågor som handlar om medarbetarnas upplevelse av Qlosr Group som arbetsgivare och involvera alla medarbetare i arbetet med företagskulturen är en viktig strategisk fråga inom Bolaget.',
			'Företaget satte upp ett mål att bli ett certifierat Great Place to Work© företag senast under 2023. Efter senaste mätningen, genomförd under oktober 2022, är nu Qlosr Group ett certifierat Great Place to Work© bolag med ett index på 79 vilket är 23 punkter över Sverigesnittet (56). Samtliga av Qlosr Groups lokala bolag som ingick i undersökningen uppfyller målet för certifiering.'
		]
	},
	{
		title:
			'Delårsrapport tredje kvartalet 2022: Koncernen fortsätter att växa organiskt och förbättrar lönsamheten jämfört med samma period föregående år',
		date: '2022-11-23',
		text: [
			'Qlosr Group AB publicerar idag sin delårsrapport för det tredje kvartalet 2022 där justerad EBITDA förbättrades med 13,4 MSEK jämfört med tredje kvartalet 2021. Verksamheten fortsatte samtidigt att växa kraftigt med en tillväxttakt på 282,9 procent, varav 19,1 procent organisk tillväxt. Periodens justerade resultat exklusive goodwillavskrivningar ökade med 9,6 MSEK jämfört med samma period föregående år.',
			'TREDJE KVARTALET JULI – SEPTEMBER 2022',
			'- Rörelsens intäkter ökade med 282,9 procent till 125,3 MSEK (32,7), varav 19,1 procent utgjordes av organisk tillväxt.',
			'- Omställningen mot prenumerationsintäkter ökade med 190,8 procent till 61,2 MSEK, varav 21,0 procent var organisk tillväxt.',
			'- Andelen prenumerationsintäkter av den totala omsättningen uppgick till 48,8 procent (64,3), vilket är över de 45,0 procent som tidigare har kommunicerats som prognos för helåret 2022.',
			'- Justerad bruttomarginal *) ökade till 39,7 procent (34,6).',
			'- Justerad EBITDA *) uppgick till 4,3 MSEK (-9,1), vilket motsvarar en marginal på 3,4 procent (-27,8) och vilket är i linje med förväntad säsongsvariation hänförligt till semesterperiod.',
			'- Justerade periodens resultat korrigerat för goodwillavskrivningar uppgick till -0,1 MSEK (-9,8).',
			'- Kassaflödet från den löpande verksamheten efter förändring i rörelsekapitalet uppgick till -10,1 MSEK (-18,6), varav 8,1 MSEK är hänförligt till engångskostnader för sammanslagning av Stockholmsenheterna och ökad egenfinansiering av hårdvaruleveranser till kunder.',
			'- Kassa och bank minskade under kvartalet med 11,2 MSEK till 48,4 MSEK som ett resultat av engångskostnader för sammanslagningen av Stockholmsenheterna, fyra genomförda förvärv och ökad egenfinansiering av hårdvaruleveranser till kunder.',
			'"Omsättningen blev högre än vad vi hade vågat hoppas på, vilket förklaras av ökade hårdvaruleveranser till skolor", säger vd Jonas Norberg.',
			'"Som det ser ut kommer vi att passera en halv miljard i omsättning 2022 och samtidigt med ett bättre kassaflöde från verksamheten än planerat, vilket vi delvis har använt till nya förvärv", säger Norberg.'
		]
	},
	{
		title: 'Qlosr Groups IT-chef ökar sitt aktieinnehav',
		date: '2022-11-23',
		text: [
			'Reza Sabaro har den 23 november köpt 10 000 B-aktier i teknikbolaget Qlosr Group där han är IT-chef. Aktierna köptes till kursen 5,14 kronor per aktie, en affär på 51 500 kronor. Det framgår av Finansinspektionens insynsregister.'
		]
	},
	{
		title: 'Analysguiden upprepar riktkurs 7,20 kronor för Qlosr Group',
		date: '2022-11-25',
		text: [
			'Omsättningen under det tredje kvartalet växte 283 procent till 125,3 miljoner kronor, vilket var i linje med analyshusets estimat. Den organiska tillväxten uppgick till drygt 19 procent och andelen prenumerationsintäkter var nästan 49 procent.',
			'Under kvartalet genomförde bolaget fyra nya förvärv som totalt adderar intäkter om 74 miljoner kronor med en ebitda på 116 miljoner.',
			'"Klart positivt är att redan nu har tre av förvärven under oktober adderat nya prenumerationsavtal på 1,9 miljoner kronor, vilket innebär att bolagets modell för integration och framtagande av synergier från förvärv är framgångsrik", skriver Analyst Group.',
			'"Trots att bolaget inte funnits på börsen så länge, har det lyckats levererar en kraftig tillväxt med lönsamhet. Bolaget är på god väg att uppnå målet om en omsättning på 500 miljoner kronor för 2022 och sedan vidare mot målet 1 miljard år 2024. Vi tycker fortsatt att bolaget är klart intressant och upprepar vår tidigare köprekommendation och riktkurs", avslutar analysfirman.'
		]
	},
	{
		title: 'Styrelseledamot i Qlosr Group ökar sitt aktieinnehav',
		date: '2022-11-25',
		text: [
			'Johan Bjerhagen har den 23 november köpt 26 900 B-aktier i teknikbolaget Qlosr Group där han är styrelseledamot. Aktierna köptes til kursen 5,09 kronor per aktie, en affär på 137 000 kronor. Affären gjordes på Nasdaq Stockholm. Det framgår av Finansinspektionens insynsregister.'
		]
	},
	{
		title: 'Qlosr Groups vd Jonas Norberg ökar sitt aktieinnehav',
		date: '2022-11-28',
		text: [
			'Teknikbolaget Qlosr Groups vd Jonas Norberg har den 24 och 25 november köpt 12 500 B-aktier i bolaget. Aktierna köptes till ett genomsnittspris på 5,14 kronor per aktie, en affär på 64 500 kronor. Affären gjordes på Nasdaq Stockholm. Det framgår av Finansinspektionens insynsregister.'
		]
	},
	{
		title: 'Analyst Group upprepar motiverat värde för Qlosr Group',
		date: '2022-12-01',
		text: [
			'Analyst Group upprepar sitt motiverade värde om 10,8 kronor för Qlosr Group, enligt en uppdragsanalys.',
			'Under tredje kvartalet ökade teknikbolaget nettoomsättningen med 283 procent till 125 miljoner kronor, jämfört med samma period i fjol. Den organiska tillväxten uppgick till 19,1 procent och var i linje med Analyst Groups estimat. Den justerade ebitda-marginalen på 3,4 procent missade dock analyshusets förväntningar.',
			'"Givet utfallet under Q3-22 kan Analyst Group konstatera att medan Qlosr har levererat i linje med våra omsättningsestimat hittills under år 2022, har samtidigt det justerade ebitda-resultatet understigit våra estimat något, varför vi har reviderat ned den justerade ebitda-prognosen för år 2022", heter det.',
			'Emellertid har analyshuset höjt såväl omsättningsestimaten som ebitda-marginalen för åren 2023-2024 givet genomförda förvärv.'
		]
	},
	{
		title: 'Mangold sänker riktkursen för Qlosr Group till 14 kronor',
		date: '2022-12-02',
		text: [
			'Mangold sänker sin riktkurs för teknikbolaget Qlosr Group till 14 kronor per aktie (18), främst på grund av lägre antagen organisk tillväxt. Det framgår av en uppdragsanalys.',
			'Jämfört med tredje kvartalet i fjol ökade teknikbolaget nettoomsättningen med 283 procent till 125 miljoner kronor. Dock var intäkterna cirka 3 procent lägre än Mangolds prognos. Den organiska tillväxten uppgick till 19,1 procent.',
			'Den justerade ebitda-marginalen var 5,1 procent under de första nio månaderna och analyshuset spår att marginalen kommer uppgå till 6,9 procent för helåret.',
			'Fyra förvärv ligger med i räkenskaperna under det fjärde kvartalet vilket bör bidra med förbättrad lönsamhet, skriver Mangold.'
		]
	},
	{
		title: 'Mangold sänker riktkursen för Qlosr Group till 14 kronor',
		date: '2022-12-02',
		text: [
			'Mangold sänker sin riktkurs för teknikbolaget Qlosr Group till 14 kronor per aktie (18), främst på grund av lägre antagen organisk tillväxt. Det framgår av en uppdragsanalys.',
			'Jämfört med tredje kvartalet i fjol ökade teknikbolaget nettoomsättningen med 283 procent till 125 miljoner kronor. Dock var intäkterna cirka 3 procent lägre än Mangolds prognos. Den organiska tillväxten uppgick till 19,1 procent.',
			'Den justerade ebitda-marginalen var 5,1 procent under de första nio månaderna och analyshuset spår att marginalen kommer uppgå till 6,9 procent för helåret.',
			'Fyra förvärv ligger med i räkenskaperna under det fjärde kvartalet vilket bör bidra med förbättrad lönsamhet, skriver Mangold.'
		]
	},
	{
		title: 'Qlosr Group AB tecknar nytt avtal med svensk friskoleaktör till ett totalt värde om cirka 39 MSEK',
		date: '2022-12-20',
		text: [
			'Qlosr Group AB (publ) (”Qlosr Group”) har tecknat ett nytt IT-avtal för att leverera paketerade prenumerationstjänster till en svensk friskoleaktör. Det totala värdet uppgår till cirka 39 MSEK och längden på kontraktet sträcker sig initialt över 3 år. Leveransen kommer att starta omedelbart och trappas upp under första kvartalet 2023 och förväntas nå fulla volymer under det tredje kvartalet 2023.',
			'En viktig del i vår strategi för organisk tillväxt är att arbeta i ett nära partnerskap med våra kunder. Det är därmed extra roligt när en befintlig kund väljer att förnya förtroendet med oss och utökar med nya tjänster. Leveransen som primärt består av våra paketerade prenumerationstjänster inom arbetsplats, serverdrift och infrastruktur kommer att tillgodose kundens behov för fortsatt tillväxt och ökade krav inom säkerhet. För att möta verksamhetens behov av hybrida arbetssätt består tjänstepaketeringarna av molntjänster från både Microsoft och Google som kompletteras av datacentertjänster som driftas i våra egna datacenter, säger Jonas Norberg Group CEO, Qlosr Group AB.',
			'Den kundnära dialogen och tekniska kompetensen inom Qlosr Group i kombination med många års erfarenhet av att framgångsrikt förse skolsektorn med driftsäkra, flexibla och moderna IT-tjänster enligt prenumerationsmodellen har varit stark bidragande till att Qlosr Group har fått förnyat förtroende hos denna kund.',
			'Qlosr Group stärker därmed sin position som en ledande aktör inom IT leveranser och digitalisering för skola- och utbildningsverksamheter i Sverige.'
		]
	},
	{
		title:
			'Qlosr Group AB tecknar förlängningsavtal med multinationellt miljardbolag till ett totalt värde om cirka 10,5 MSEK',
		date: '2022-12-20',
		text: [
			'Qlosr Group AB har tecknat ett förlängningsavtal för att leverera paketerade prenumerationstjänster till ett multinationellt miljardbolag inom detaljhandel med över 6000 medarbetare och omsättning på över 40 miljarder per år. Det totala värdet uppgår till cirka 10,5 MSEK och längden på kontraktet sträcker sig initialt över 1 år.',
			'Vi ser fram emot att fortsätta leverera paketerade prenumerationstjänster av hög kvalitet. Leveransen som primärt omfattas av nätverkstjänster, IT-drift och konsulttjänster bidrar till att förenkla kundens vardagliga hantering, säger Jonas Norberg Group CEO, Qlosr Group AB.',
			'En djup teknisk kompetens och nära partnerskap mellan Qlosr Group och kunden har varit starkt bidragande till att Qlosr Group har fått förnyat förtroende.'
		]
	},
	{
		title: 'Qlosr Group AB tecknar nytt IT-avtal med Lärandegruppen i Sverige AB',
		date: '2022-12-22',
		text: [
			'Teknikbolaget Qlosr Group har tecknat ett IT-avtal för att leverera paketerade prenumerationstjänster till Lärandegruppen i Sverige. Kontraktsvärdet uppgår initialt till ungefär 1 miljon kronor per år, framgår det av ett pressmeddelande.',
			'- Affären har ett stort strategiskt värde för Qlosr Group som med detta ytterligare förstärker sin position som en av landets ledande aktörer inom IT-tjänster för skola och utbildningssektorn, kommenterar vd Jonas Norberg.',
			'Avtalet löper över 1 plus 2 år med årsvis förlängning därefter. Leveransen påbörjas under innevarande kvartal och beräknas nå fulla volymer under första kvartalet nästa år.'
		]
	},
	{
		title: 'Qlosr Group AB tecknar tilläggsavtal med svensk friskolekoncern till ett totalt värde om cirka 18 MSEK',
		date: '2022-12-28',
		text: [
			'Qlosr Group AB (publ) (”Qlosr Group”) har tecknat ett tilläggsavtal för att leverera paketerade prenumerationstjänster för nätverk till en av Sveriges största friskolekoncerner, med över 45 skolor i Sverige. Det totala värdet uppgår till cirka 18 MSEK och längden på kontraktet sträcker sig initialt över 4,5 år. Leveransen kommer att starta omgående och trappas upp under första kvartalet 2023 för att fortgå löpande under hela 2023.',
			'- Affärsmodellen som bygger på ett nära partnerskap med kunden och att ständigt utmana och utveckla oss och våra tjänster resulterar ännu en gång i att kunden väljer att avropa nya paketerade prenumerationstjänster från Qlosr Group. Genom att addera nätverkstjänster till den befintliga leveransen stärker vi vår position och förblir en strategisk viktig partner till kunden i deras fortsatta tillväxt. När många av våra större kunder väljer att både förlänga och utveckla ger det oss en trygghet i att vår tillväxt på tillväxtstrategi fungerar på det sätt vi önskar, säger Jonas Norberg Group CEO, Qlosr Group AB.',
			'Tilläggsavtalet omfattar nätverkstjänster som trådlöst nätverk, lokalt nätverk och central infrastruktur och ska levereras i hela Sverige till merparterna av kundens skolor och verksamheter.',
			'Qlosr Group befäster därmed sin position som en ledande aktör inom IT leveranser och digitalisering för skola- och utbildningsverksamheter i Sverige.'
		]
	},
	{
		title: 'Qlosr Group tecknar avtal med Brixly värt 15 miljoner kronor',
		date: '2023-01-16',
		text: [
			'Brixly AB (”Brixly”) har genomfört en upphandling avseende IT-drift, support och managering av klienter. Qlosr Group AB (publ) (”Qlosr Group”) har tilldelats beslut om att teckna avtal med Brixly avseende prenumerationstjänster för samtliga upphandlade områden. Det totala värdet uppgår till cirka 15 MSEK och avtalstiden är fem (5) år från avtalsstart, med möjlighet till förlängning.',
			'Vi är otroligt glada att Brixly har valt Qlosr Group som ny IT-partner och vi ser detta som ett tydligt kvitto på att våra paketerade prenumerationstjänster tillsammans med en tydlig förvärvsstrategi är uppskattat av marknaden. Affären visar också att Qlosr Groups strategi med korsförsäljning och samarbeten mellan förvärvade bolag skapar nya kundvärden som leder till fler affärer, säger Jonas Norberg Group CEO, Qlosr Group AB (publ)',
			'Brixly AB är en av de största byggaktörerna i Västsverige. Förutom att driva ett framgångsrikt byggföretag arbetar de för att tydligt och konkret bidra till livskraftiga, attraktiva och trygga samhällen där människor, företag och verksamheter kan leva, utvecklas och frodas på ett hållbart sätt. Brixly har knappt 500 anställda och omsättningen för 2021 uppgick till 2,3 miljarder och med ett resultat på 45,7 miljoner före skatt. Bolagets säte är i Mölndal och de verkar i hela Västsverige.',
			'Införandeprojektet kommer att genomföras under första halvåret 2023 och partnerskapet mellan Qlosr Group och Brixly ska leda till fortsatt utveckling av nya tjänster, digitalisering och kundvärde under hela avtalstiden. Förutom en hög teknisk kompetens inom de upphandlade tjänsterna har Brixly värderat följande områden som högt bidragande till att Qlosr Group valts till ny IT-partner. Likheterna i respektive bolags tillväxtresa och geografisk spridning samt den värdegrund som bolagen utgår ifrån. Laganda, ansvar och personlighet är några av de ledord som beskriver grunden för ett välfungerande partnerskap och som ger nöjda medarbetare och nöjda kunder.'
		]
	},
	{
		title: 'Qlosr Group vinner tilldelningsbeslut med Kristinehamns kommun värt 40 miljoner kronor',
		date: '2023-02-02',
		text: [
			'Kristinehamns kommun har genomfört en offentlig upphandling (LOU) avseende tjänster för IT-arbetsplats, skoldatorer och tillhörande konfigurationstjänster. Upphandlingen gäller Kristinehamns kommun, där även Storfors kommun har fullmakt att avropa tjänsterna. Qlosr Group AB (publ) nedan kallat (“Qlosr Group” eller “Bolaget”) har erhållit tilldelningsbeslut om att teckna avtal med Kristinehamns kommun avseende samtliga upphandlade tjänsteområden. Det totala värdet uppgår till cirka 40 MSEK enligt Kristinehamns kommun och avtalstiden är två (2) år från avtalsstart, med möjlighet till förlängning. Maximal avtalstid är fyra (4) år.',
			'Upphandlingen omfattar IT-arbetsplatser, skoldatorer och tillhörande konfigurationstjänster. Resultatet blir en livscykelhanterad tjänst med hårdvara och support från installation och leverans till återtag och miljöåtervinning baserat på PC och Chromebook.',
			'Tilldelningsbeslutet från Kristinehamns kommun bekräftar återigen att vår strategi gällande fortsatt tillväxt hos förvärvade lokala bolag fungerar som planerat. Med våra samlade resurser i koncernen och med lokal närvaro i regionen kommer vi att leverera efterfrågade tjänster på ett strukturerat och effektivt sätt med en hög kundnöjdhet, säger Jonas Norberg CEO, Qlosr Group'
		]
	},
	{
		title: 'Qlosr Group erhåller tilldelningsbeslut med Västra Götalandsregionen till ett värde av cirka 62 MSEK',
		date: '2023-02-21',
		text: [
			'Västra Götalandsregionen, även kallat (”VGR”) har genomfört en förnyad konkurrensutsättning enligt Addas Ramavtal Digitala enheter för arbetsplats och skola 2019. Qlosr Group AB har erhållit tilldelningsbeslut om att teckna avtal med VGR avseende leverans av datorer för IT-arbetsplats för VGR:s verksamheter. Det totala värdet uppgår till cirka 62 MSEK. Avrop och leverans kommer att ske under perioden 2023-04-01 till 2023-08-31.',
			'Leveransen omfattar cirka 7500 IT-arbetsplatser med tillhörande produktnära tjänster.'
		]
	},
	{
		title: 'Analyst Group räknar med att Qlosr Group når sina mål för 2024',
		date: '2023-02-24',
		text: [
			'Teknikbolaget Qlosr Group släppte i morse en rapport som levererade på de uppsatta målen för 2022. Det skriver Analyst Group i en kommentar efter rapporten.',
			'Det visar på att Qlosr ställer rimliga mål och ger därmed fortsatt förtroende för att bolaget kommer att nå utstakade mål för år 2024, noterar Analyst Group i en rapportkommentar.',
			'Omsättningen under kvartalet steg 78,0 procent på årsbasis till 127,1 miljoner kronor.',
			'Bolaget siktar på att omsätta 1 miljard 2024 med en ebita på 10-12 procent.'
		]
	},
	{
		title: 'Qlosr Group ökar omsättning och förbättrade justerat ebitda-resultat',
		date: '2023-02-24',
		text: [
			'Teknikbolaget Qlosr Group redovisar ökande omsättning under fjärde kvartalet jämfört med samma period året innan. Det justerade ebitda-resultatet samt nettoresultatet förbättrades också. Styrelsen föreslår ingen utdelning.',
			'Omsättningen steg 78,0 procent till 127,1 miljoner kronor (71,4). Den organiska försäljningstillväxten var 13,8 procent. Totala intäkter var 145,5 miljoner kronor (83,1).',
			'Prenumerationsintäkter ökade med 69,1 procent till 62,3 miljoner kronor, varav 14,6 procent var organisk tillväxt. Andelen prenumerationsintäkter av den totala intäkten uppgick till 42,9 procent (44,4).',
			'Justerat ebitda-resultat uppgick till 17,7 miljoner kronor (10,5), med en justerad ebitda-marginal på 13,9 procent (14,7).',
			'Rörelseresultatet blev -5,2 miljoner kronor (-40,6). Resultatet efter skatt blev -5,5 miljoner kronor (-38,7).',
			'"Jag är stolt över att vi under 2022 visade stark tillväxt och ökad lönsamhet. Vi har träffat det högre spannet av vår tidigare guidning med en justerad ebitda på 7,1 procent, och vår tillväxt-på-tillväxt strategi har gett oss en organisktillväxt på 21 procent, samtidigt som vi har gjort förvärv enligt plan som stärker vår position på marknaden", skriver vd Jonas Norberg i rapporten.',
			'"Finansiellt har vi under 2022 levererat enligt plan eller bättre både vad gäller totala intäkter, avtalsintäkter, bruttomarginal och ebitda marginal."',
			'Qlosr Group har flera finansiella mål för 2024. 60 procent av omsättningen ska komma från prenumerationsaffären, prenumerationsaffären ska generera en bruttomarginal på 55 procent., ebita ska ligga på 10–12 procent och omsättningsmålet är 1 miljard kronor.'
		]
	},
	{
		title: 'Analysguiden upprepar motiverat värde för Qlosr Group',
		date: '2023-03-01',
		text: [
			'Analysguiden upprepar sitt motiverade värde för teknikbolaget Qlosr Group på 7,20 kronor, enligt en färsk uppdragsanalys.',
			'Intäkterna för det fjärde kvartalet ökade 75 procent på årsbasis till 145,5 miljoner kronor (83,1). Den organiska tillväxten var 13,8 procent.',
			'Totalt för helåret landade omsättningen på 515,6 miljoner kronor, vilket överträffade målet på 500 miljoner kronor. Bolaget har som mål att omsätta 1 miljard kronor 2024, vilket Analysguiden tror kommer uppnås givet den organiska tillväxten och förvärvstempot.'
		]
	},
	{
		title: 'Analyst Group upprepar motiverat för Qlosr Group',
		date: '2023-03-06',
		text: [
			'Analyst Group bibehåller motiverat värde på 10,8 kronor för teknikbolaget Qlosr Group. Det framgår av en uppdragsanalys.',
			'Nettoomsättningen översteg en halv miljard under år 2022 och den justerade ebitda-marginalen kom in i det övre kommunicerade intervallet.',
			'"Således levererade Qlosr på bolagets utstakade mål för år 2022, vilket ingjuter förtroende för att Qlosr även kommer att nå uppsatta mål för år 2024", skriver analyshuset.',
			'Det motiverade värdet härleds av en ev/ebitda-multipel på 10x för 2023 års estimat.'
		]
	},
	{
		title: 'Mangold sänker riktkursen för Qlosr Group till 13 kronor',
		date: '2023-03-07',
		text: [
			'Mangold sänker sin riktkurs för teknikbolaget Qlosr Group till 13 kronor per aktie (14) på 12 månaders sikt med anledning att man förväntar sig lägre organisk tillväxt framöver. Det framgår av en färsk uppdragsanalys.',
			'Avkastningskravet på bolaget har också skruvats upp till 12 procent, vilket matematiskt sänker värdet av framtida kassaflöden och således även den framräknade riktkursen.',
			'Qlosrs fjärde kvartal kom in lite lägre än Mangolds estimat på omsättning men över på ebitda-marginalen.'
		]
	},
	{
		title: 'Qlosr Group erhåller tilldelningsbeslut med Uddevalla kommun till ett värde av cirka 100 MSEK',
		date: '2023-03-14',
		text: [
			'Uddevalla kommun har genomfört en samordnad upphandling av datorer, skärmar, dockningsstationer och tillbehör med syfte att teckna ramavtal med en leverantör som kan tillgodose kommunens löpande behov av produkterna under avtalsperioden. Qlosr Group AB (publ) nedan kallat (“Qlosr Group” eller “Bolaget”) har erhållit tilldelningsbeslut om att teckna avtal med Uddevalla kommun avseende samtliga upphandlade områden. Beställningsvolymen/värdet av denna upphandling uppskattas till, över en fyra (4) årsperiod, cirka 100 MSEK fördelat på cirka 25 MSEK per år. Avtalstiden är två (2) år från avtalsstart, med möjlighet till förlängning ett (1) år plus ett (1) år. Maximal avtalstid är fyra (4) år.',
			'"Tilldelningsbeslutet från Uddevalla kommun bekräftar återigen att vår affärsenhet för offentligsektor fungerar väl och att vårt fortsatta fokus på kvalitativa och kostnadseffektiva leveranser uppskattas av kunderna", säger Jonas Norberg CEO, Qlosr Group'
		]
	}
];

const Features = () => {
	const descFeaturesData = featuresData.sort((a, b) => b.date.localeCompare(a.date));

	return (
		<div className="qlosr__features section__padding" id="pressmeddelanden">
			<div className="qlosr__features-heading">
				<h1 className="gradient__text">Pressmeddelanden</h1>
				{/*
				<p>Pressmeddelanden</p>
			*/}
			</div>
			<div className="qlosr__features-container">
				{descFeaturesData.map((item, index) => (
					<Feature title={item.title} date={item.date} text={item.text} key={item.title + index} />
				))}
			</div>
		</div>
	);
};

export default Features;
