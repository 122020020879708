import React, { useState } from 'react';
import { BiRightArrow, BiUpArrow } from 'react-icons/bi';
import './feature.css';

const Feature = ({ title, date, text }) => {
	const [ showFeatureText, setFeatureText ] = useState(false);

	return (
		<div className="qlosr__features-container__feature">
			<div className="qlosr__features-container__feature-title">
				<div />
				<h1>{title}</h1>
				<h4>{date}</h4>
			</div>

			<div className="qlosr__features-container_feature-text">
				{showFeatureText ? (
					text.map((item, index) => <p key={index}>{item}</p>)
				) : (
					<p style={{ cursor: 'pointer' }} onClick={() => setFeatureText(true)}>
						<div className="qlosr__features-container_feature-text_icon">
							Läs mer <BiRightArrow style={{ marginLeft: '4' }} />
						</div>
					</p>
				)}
				{showFeatureText && (
					<p style={{ cursor: 'pointer' }} onClick={() => setFeatureText(false)}>
						<div className="qlosr__features-container_feature-text_icon" style={{ width: 17 }}>
							<BiUpArrow />
						</div>
					</p>
				)}
			</div>
		</div>
	);
};

export default Feature;
