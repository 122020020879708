import React from 'react';
import VideoItem from '../videoItem/VideoItem';
import './videoList.css';

const VideoList = ({ videos, onVideoSelect }) => {
	const listOfVideos = videos.map((video, id) => <VideoItem onVideoSelect={onVideoSelect} video={video} key={id} />);
	return <div className="video-list-container">{listOfVideos}</div>;
};

export default VideoList;
