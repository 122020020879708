import React from 'react';
import './videoItem.css';

const VideoItem = ({ video, onVideoSelect }) => {
	// const videoSrc = `https://www.youtube.com/embed/${video.url}`;

	return (
		<div className="video-item-container">
			<div
				style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
				onClick={() => onVideoSelect(video)}
			>
				<h4 className="video-item">
					<a href="#intervjuer">{video.title}</a>
				</h4>
			</div>
		</div>
	);
};

export default VideoItem;
