import React from 'react';
import './brand.css';
import { vibeit, bmore, rg19, sbldata } from './imports';

const Brand = () => {
	return (
		<div className="qlosr__brand" id="brands">
			<div>
				<img src={vibeit} alt="vibeit" />
			</div>
			<div>
				<img src={bmore} alt="bmore" />
			</div>
			<div>
				<img src={rg19} alt="rg19" />
			</div>
			<div>
				<img src={sbldata} alt="sbldata" />
			</div>
		</div>
	);
};

export default Brand;
