import React from 'react';
import './possibility.css';

const Possibility = () => {
	return (
		<div className="qlosr__possibility section__margin" id="insynshandel">
			<div className="qlosr__possibility-heading">
				<h1>Insynshandel (fi.se)</h1>
			</div>
			<div className="qlosr__possibility-content">
				<p>
					2022-11-25 Qlosr Group AB Jonas Norberg, Verkställande direktör (VD) Förvärv Qlosr Group B Aktie
					2000st 5,00sek
				</p>
				<p>
					2022-11-25 Qlosr Group AB Johan Daniel Bjerhagen, Styrelseledamot Förvärv Qlosr Group B Aktie
					10500st 5,00sek
				</p>
				<p>
					2022-11-24 Qlosr Group AB Jonas Norberg, Verkställande direktör (VD) Förvärv Qlosr Group B Aktie
					3500st 5,10sek
				</p>
				<p>
					2022-11-24 Qlosr Group AB Jonas Norberg, Verkställande direktör (VD) Förvärv Qlosr Group B Aktie
					3000st 5,18sek
				</p>
				<p>
					2022-11-24 Qlosr Group AB Jonas Norberg, Verkställande direktör (VD) Förvärv Qlosr Group B Aktie
					4000st 5,22sek
				</p>

				<p>
					2022-11-23 Qlosr Group AB Johan Daniel Bjerhagen, Styrelseledamot Förvärv Qlosr Group B Aktie
					22000st 5,08sek
				</p>
				<p>
					2022-11-23 Qlosr Group AB Johan Daniel Bjerhagen, Styrelseledamot Förvärv Qlosr Group B Aktie 4900st
					5,10sek
				</p>
				<p>
					2022-11-23 Qlosr Group AB Reza Sabaro, Arbetstagarrepresentant i styrelsen eller
					arbetstagarsuppleant Förvärv Qlosr Group B Aktie 10000st 5,14sek
				</p>
				<p>
					2022-08-25 Qlosr Group AB Urban Norberg, Ekonomichef/finanschef/finansdirektör Förvärv Qlosr Group B
					Aktie 4000st 5,89sek
				</p>
				<p>
					2022-08-24 Qlosr Group AB Urban Norberg, Ekonomichef/finanschef/finansdirektör Förvärv Qlosr Group B
					Aktie 12 000st 6,00sek
				</p>
				<p>
					2022-08-23 Qlosr Group AB Urban Norberg, Ekonomichef/finanschef/finansdirektör Förvärv Qlosr Group B
					Aktie 3500st 6,30sek
				</p>
				<p>
					2022-08-23 Qlosr Group AB Urban Norberg, Ekonomichef/finanschef/finansdirektör Förvärv Qlosr Group B
					Aktie 7000st 6,59sek
				</p>
				<p>- OMVÄND AKTIESPLIT 1:200 -</p>
				<p>
					2022-06-16 Qlosr Group AB Joakim Ribb, Styrelseledamot Förvärv Qlosr Group B Aktie 257 000st
					0,038sek
				</p>
				<p>
					2022-06-16 Qlosr Group AB Joakim Ribb, Styrelseledamot Förvärv Qlosr Group B Aktie 86 355st
					0,0347sek
				</p>
				<p>
					2022-06-16 Qlosr Group AB Joakim Ribb, Styrelseledamot Förvärv Qlosr Group B Aktie 17 626st
					0,0339sek
				</p>

				<p>
					2022-06-16 Qlosr Group AB Joakim Ribb, Styrelseledamot Förvärv Qlosr Group B Aktie 148 887st
					0,0334sek
				</p>
				<p>
					2022-06-16 Qlosr Group AB Joakim Ribb, Styrelseledamot Förvärv Qlosr Group B Aktie 300 000st
					0,033sek
				</p>
				<p>
					2022-06-16 Qlosr Group AB Joakim Ribb, Styrelseledamot Förvärv Qlosr Group B Aktie 300 000st
					0,033sek
				</p>
				<p>
					2022-06-16 Qlosr Group AB Joakim Ribb, Styrelseledamot Förvärv Qlosr Group B Aktie 384 900st
					0,033sek
				</p>
				<p>
					2022-06-16 Qlosr Group AB Joakim Ribb, Styrelseledamot Förvärv Qlosr Group B Aktie 316 371st
					0,0329sek
				</p>
				<p>
					2021-12-06 Qlosr Group AB Joakim Ribb Verkställande direktör (VD), Styrelseledamot Förvärv Qlosr
					Group B Aktie 10.366.000st 0,048sek
				</p>
				<p>
					2021-12-01 Qlosr Group AB Joakim Ribb Verkställande direktör (VD) Förvärv Qlosr Group B Aktie
					7.000.000st 0,049sek
				</p>
				<p>
					2021-12-01 Qlosr Group AB Joakim Ribb Verkställande direktör (VD) Förvärv Qlosr Group B Aktie
					3.000.000st 0,045sek
				</p>
				<p>
					2021-12-01 Qlosr Group AB Joakim Ribb Verkställande direktör (VD) Förvärv Qlosr Group B Aktie
					680.000st 0,044sek
				</p>
				<p>
					2021-12-01 Qlosr Group AB Joakim Ribb Verkställande direktör (VD) Förvärv Qlosr Group B Aktie
					500.000st 0,044sek
				</p>
				<p>
					2021-10-06 Qlosr Group AB Baran Demir Annan ledande befattningshavare Teckning Qlosr Group B BTU
					(betald tecknad unit) 555.555st 0,45sek
				</p>
				<p>
					2021-10-01 Qlosr Group AB Anders Jäderling Ekonomichef/finanschef/finansdirektör Teckning Qlosr
					Group B Aktie 880.000st 0,45sek
				</p>
				<p>
					2021-10-01 Qlosr Group AB Anders Jäderling Ekonomichef/finanschef/finansdirektör Teckning Qlosr
					Group B Aktie 81.000st 0,45sek
				</p>
				<p>
					2021-10-01 Qlosr Group ab Anders Jäderling Ekonomichef/finanschef/finansdirektör Teckning Qlosr
					Group B BTU (betald tecknad unit) 1.000.000st 0,45sek
				</p>
				<p>
					2021-09-20 Qlosr Group AB Johan Bjerhagen Styrelseledamot Teckning Qlosr Group B Aktie 277.777st
					0,45sek
				</p>
				<p>
					2021-09-20 Qlosr Group AB Johan Bjerhagen Styrelseledamot Teckning Qlosr Group B Aktie 222.222st
					0,45sek
				</p>
				<p>
					2021-09-20 Qlosr Group AB Johan Bjerhagen Styrelseledamot Teckning Qlosr Group B Aktie 1.111.111st
					0,45sek
				</p>
				<p>
					2021-09-20 Qlosr Group AB Joakim Ribb Verkställande direktör (VD) Teckning Qlosr Group B BTU (betald
					tecknad unit) 1.111.111st 0,45sek
				</p>
				<p>
					2021-09-20 Qlosr Group AB Gustav Tjernström Annan medlem i bolagets administrations-, lednings-
					eller kontrollorgan Teckning Qlosr Group B BTU (betald tecknad unit) 222.222st 0,45sek
				</p>
				<p>
					2021-09-20 Qlosr Group AB Martin Öster Annan medlem i bolagets administrations-, lednings- eller
					kontrollorgan Teckning Qlosr Group B BTU (betald tecknad unit) 400.000st 0,45sek
				</p>
				<p>
					2021-09-14 Qlosr Bengt Engström styrelseordförande Förvärv Qlosr BTU (betald tecknad unit)
					1.111.111st 0,45sek
				</p>
				<p>
					2021-09-07 Qlosr Group AB Martin Öster Annan medlem i bolagets administrations-, lednings- eller
					kontrollorgan Teckning Qlosr Group B BTU (betald tecknad unit) 200.000st 0,45sek
				</p>
				<p>
					2021-08-17 Qlosr Group AB Anders Jäderling Ekonomichef/finanschef/finansdirektör Förvärv Qlosr Group
					B Aktie 500.000st 0,068sek
				</p>
				<p>
					2021-08-17 Qlosr Group AB Anders Jäderling Ekonomichef/finanschef/finansdirektör Förvärv Qlosr Group
					B Aktie 500.000st 0,068sek
				</p>
				<p>
					2021-08-16 Qlosr Group AB Anders Jäderling Ekonomichef/finanschef/finansdirektör Förvärv Qlosr Group
					B Aktie 367.375st 0,067sek
				</p>
				<p>
					2021-08-13 Qlosr Group AB Anders Jäderling Ekonomichef/finanschef/finansdirektör Förvärv Qlosr Group
					B Aktie 600.000st 0,068sek
				</p>
				<p>
					2021-08-13 Qlosr Group AB Anders Jäderling Ekonomichef/finanschef/finansdirektör Förvärv Qlosr Group
					B Aktie 1.587.139st 0,066sek
				</p>
				<p>
					2021-08-13 Qlosr Group AB Anders Jäderling Ekonomichef/finanschef/finansdirektör Förvärv Qlosr Group
					B Aktie 1.197.099st 0,068sek
				</p>
				<p>
					2021-08-13 Qlosr Group AB Anders Jäderling Ekonomichef/finanschef/finansdirektör Förvärv Qlosr Group
					B Aktie 2.500.000st 0,068sek
				</p>
				<p>
					2021-08-13 Qlosr Group AB Anders Jäderling Ekonomichef/finanschef/finansdirektör Förvärv Qlosr Group
					B Aktie 2.415.762st 0,067sek
				</p>
				<p>
					2021-06-22 Qlosr Group AB Martin Öster Annan medlem i bolagets administrations-, lednings- eller
					kontrollorgan Förvärv Aktie 1.200.000st 0,07sek
				</p>
				<p>
					2021-06-14 Qlosr Holding AB Anders Jäderling Ekonomichef/finanschef/finansdirektör Förvärv Qlosr
					Group B Aktie 700.000st 0,062sek
				</p>
			</div>
		</div>
	);
};

export default Possibility;
