import React from 'react';
import './videoDetail.css';

const VideoDetail = ({ video }) => {
	if (!video) return <div>Loading...</div>;
	const videoSrc = `https://www.youtube.com/embed/${video.url}`;
	// const videoSrc = video;
	// console.log('video: ', video);
	return (
		<div className="video-detail-container">
			<div className="video">
				<iframe frameBorder="0" src={videoSrc} title="Video Player" width="100%" height="100%" />
			</div>

			<div className="details">
				<h6>{video.title}</h6>
			</div>
		</div>
	);
};

export default VideoDetail;
