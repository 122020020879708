import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine, RiHeartFill } from 'react-icons/ri';

import './navbar.css';

const Menu = () => (
	<div className="qlosr__navbar-menu_links-container">
		{/*
		<p>
		<a href="#home">Hem</a>
		</p>
	*/}

		{/*
			<p>
			<a href="#blog">Nyheter</a>
			</p>
		*/}
		{/*
			<p>
			<a href="#wqlosr">Qlosr?</a>
			</p>
		*/}
		<div className="qlosr__navbar-menu_links-container_main">
			<p>
				<a href="#analyser">Analyser</a>
			</p>
			<p>
				<a href="#pressmeddelanden">Pressmeddelanden</a>
			</p>
			<p>
				<a href="#intervjuer">Intervjuer</a>
			</p>

			<p>
				<a href="#insynshandel">Insynshandel</a>
			</p>
		</div>

		<div className="qlosr__navbar-menu_links-container_support">
			<p>
				<a href="#support">
					<RiHeartFill />
				</a>
			</p>
		</div>
	</div>
);

const Navbar = () => {
	const [ toggleMenu, setToggleMenu ] = useState(false);

	return (
		<div className="qlosr__navbar">
			<div className="qlosr__navbar-links">
				<div className="qlosr__navbar-links_logo">
					<p>
						<a href="#start">Alternativ Qlosr</a>
					</p>
				</div>

				<div className="qlosr__navbar-links_container">
					<Menu />
				</div>
			</div>
			{/*
				<div className="qlosr__navbar-sign">
				<p>Logga in</p>
				<button type="button">Skapa konto</button>
				</div>
			*/}
			<div className="qlosr__navbar-menu">
				{toggleMenu ? (
					<RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
				) : (
					<RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
				)}

				{toggleMenu && (
					<div className="qlosr__navbar-menu_container scale-up-center">
						<div className="qlosr__navbar-menu_container-links" onClick={() => setToggleMenu(false)}>
							<Menu />
							{/*
							<div className="qlosr__navbar-menu_container-links-sign">
							<p>Logga in</p>
							<button type="button">Skapa konto</button>
							</div>
						*/}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Navbar;
