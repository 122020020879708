import React from 'react';
import './header.css';
import people from '../../assets/jonas_vd.png';
import ai from '../../assets/cyberlocks.png';

const Header = () => {
	return (
		<div className="qlosr__header section__padding" id="start">
			<div className="qlosr__header-content">
				<p>
					Denna sida drivs av en privatperson och är inte en del av Qlosr Group AB. Syftet med Alternativ
					Qlosr är att intresserade ska kunna hitta en del information som rör Qlosr enklare på en samlad
					sida. För fullständig information om bolaget hittar du på deras hemsida{' '}
					<a
						target="_blank"
						rel="noreferrer"
						href="https://qlosr.se/"
						style={{ textDecoration: 'underline' }}
					>
						Qlosr.se
					</a>
				</p>
				<h1 className="gradient__text">
					“Vi förstår, utvecklar och skapar positiv skillnad för våra kunder” -Qlosr
				</h1>
				<p>
					Qlosr Group AB (publ) är en helhetsleverantör av IT-lösningar vilket omfattar försäljning och
					leverans av paketerade prenumerationstjänster. Största delen av verksamheten bedrivs på den nordiska
					marknaden och består av paketerade IT-tjänster inom arbetsplats, nätverk och serverdrift. Bolagets
					tre primära målgrupper utgörs av små- och medelstora företag, offentlig sektor samt den privata
					skolsektorn med fokus på större nationella skolkoncerner.
				</p>

				{/*
					<div className="qlosr__header-content__input">
					<input type="email" placeholder="Din e-post" />
					<button type="button">Sätt igång</button>
					</div>
				*/}
				<div
					className="qlosr__header-content__people"
					style={{
						backgroundImage: `url(${ai})`,
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat'
					}}
				>
					<img src={people} alt="poeople" width="120" />
					<div className="qlosr__header-content__people-text_container">
						<p>"Vi ska fortsätta med det vi har gjort men i större skala"</p>
						<p
							style={{
								fontSize: 13,
								paddingRight: 6,
								textAlign: 'end'
							}}
						>
							Jonas Norberg, VD Qlosr
						</p>
					</div>
				</div>

				<h4 className="qlosr__header-content_subtitle gradient__text" id="analyser">
					Analyser och kommentarer
				</h4>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://analystgroup.se/kommentarer/analyst-group-kommenterar-qlosrs-senaste-tilldelningsbeslut-med-uddevalla-kommun/"
					target="_blank"
					rel="noreferrer"
				>
					Analyst Group – 2023-03-16 – Kommenter på Qlosrs senaste tilldelningsbeslut med Uddevalla kommun
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://mb.cision.com/Main/20782/3728353/1894236.pdf"
					target="_blank"
					rel="noreferrer"
				>
					Mangold Insight – 2023-03-07 – Målen går i lås (pdf)
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://analystgroup.se/analyser/qlosr-group-q4-22/"
					target="_blank"
					rel="noreferrer"
				>
					Analyst Group – 2023-03-06 – Levererar på utstakade mål
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://www.aktiespararna.se/analysguiden/nyheter/analys-qlosr-group-overtraffade-malet-om-500-mkr-i-arsomsattning"
					target="_blank"
					rel="noreferrer"
				>
					Analysguiden – 2023-03-01 – Överträffade målet om 500 mkr i årsomsättning
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://cr.abgsc.com/foretag/qlosr/Equity-research/2023/2/qlosr---2022-goal-achieved-on-to-the-next-one/"
					target="_blank"
					rel="noreferrer"
				>
					ABG Sundal Collier – 2023-02-28 – 2022 goal achieved, on to the next one
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://analystgroup.se/kommentarer/kommentar-pa-qlosrs-q4-rapport/"
					target="_blank"
					rel="noreferrer"
				>
					Analyst Group – 2023-02-24 – Kommentar på Qlosrs Q4-rapport
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://cr.abgsc.com/foretag/qlosr/Equity-research/2023/2/qlosr---solid-quarter-and-23-guidance/"
					target="_blank"
					rel="noreferrer"
				>
					ABG Sundal Collier – 2023-02-24 – Solid quarter and '23 guidance
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://news.cision.com/se/mangold-insight/r/qlosr-group---undervarderad-forvarvsmaskin---mangold-insight-analys,c3676473"
					target="_blank"
					rel="noreferrer"
				>
					Mangold Insight – 2022-12-02 – Undervärderad förvärvsmaskin
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://analystgroup.se/analyser/qlosr-group-q3-22/"
					target="_blank"
					rel="noreferrer"
				>
					Analyst Group – 2022-12-01 – Förvärvsresan fortsätter
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://www.aktiespararna.se/analysguiden/nyheter/analys-qlosr-group-hogre-tillvaxt-vantat-i-ett-forvarvdrivet-kvartal"
					target="_blank"
					rel="noreferrer"
				>
					Analysguiden – 2022-11-25 – Högre tillväxt än väntat i ett förvärvdrivet kvartal
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://analystgroup.se/kommentarer/kommentar-pa-qlosr-groups-q3-rapport/"
					target="_blank"
					rel="noreferrer"
				>
					Analyst Group – 2022-11-24 – Kommentar på Qlosr Groups Q3-rapport
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://www.aktiespararna.se/analysguiden/nyheter/initieringsanalys-qlosr-group-lonsam-snabbvaxare-inom-it-tjanster"
					target="_blank"
					rel="noreferrer"
				>
					Analysguiden – 2022-08-30 – Lönsam snabbväxare inom IT-tjänster
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://www.analystgroup.se/kommentarer/kommentar-pa-qlosrs-q2-rapport/"
					target="_blank"
					rel="noreferrer"
				>
					Analyst Group – 2022-08-24 – Kommentar på Qlosrs Q2-rapport
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://www.analystgroup.se/analyser/qlosr-group/"
					target="_blank"
					rel="noreferrer"
				>
					Analyst Group – 2022-07-05 – Lägger i nästa växel
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://mb.cision.com/Main/20782/3571134/1582406.pdf"
					target="_blank"
					rel="noreferrer"
				>
					Mangold Insight – 2022-05-20 – Integrerade förvärv förbättrar lönsamheten (pdf)
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://mb.cision.com/Main/20782/3516962/1542874.pdf"
					target="_blank"
					rel="noreferrer"
				>
					Mangold Insight - 2022-03-02 - Qlosr Group - Femfaldigt förvärv förgyller framtiden
				</a>
				<a
					className="qlosr__header-content_subtitle-text"
					href="https://www.analystgroup.se/kommentarer/kommentar-pa-qlosr-groups-bokslutskommunike/"
					target="_blank"
					rel="noreferrer"
				>
					Analyst Group - 2022-02-23 - Kommenterar Qlosr Groups bokslutskommuniké
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://www.analystgroup.se/kommentarer/analyst-group-kommenterar-senaste-handelserna-i-qlosr-group/"
					target="_blank"
					rel="noreferrer"
				>
					Analyst Group - 2021-12-20 - Kommenterar senaste händelserna i Qlosr Group
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://mb.cision.com/Main/20782/3409884/1464217.pdf"
					target="_blank"
					rel="noreferrer"
				>
					Mangold Insight – 2021-09-06 - Fler prenumerationsintäkter blir lönsamt (pdf)
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://www.analystgroup.se/analyser/qlosr/"
					target="_blank"
					rel="noreferrer"
				>
					Analyst Group - 2021-08-27 - Unik positionering på IT-marknaden
				</a>

				<a
					className="qlosr__header-content_subtitle-text"
					href="https://mb.cision.com/Main/20782/3381378/1442144.pdf"
					target="_blank"
					rel="noreferrer"
				>
					Mangold Insight – 2021-07-07 - Kundnära IT-lösning ger tillväxt (pdf)
				</a>
			</div>
			{/*
				<div className="qlosr__header-image">
				<img src={ai} alt="ai" />
				</div>
			*/}
		</div>
	);
};

export default Header;
