import React from 'react';
import { Features, Footer, Header, Possibility, WhatQlosrFans } from './containers';
import { Brand, Navbar } from './components';
import './App.css';

// BEM -> Block Element Modifier (css naming convention)
const App = () => {
	return (
		<div className="App">
			<div className="gradient__bg">
				<Navbar />
				<Header />
			</div>

			<Brand />
			<Features />
			<WhatQlosrFans />
			<Possibility />
			{/*
				<CTA />
				<Blog />
			*/}
			<Footer />
		</div>
	);
};

export default App;
