import React from 'react';
import './footer.css';
import { BiRocket } from 'react-icons/bi';
import { AiOutlineRight } from 'react-icons/ai';
import { IoLogoLinkedin } from 'react-icons/io';

const Footer = () => {
	return (
		<div className="qlosr__footer section__padding" id="support">
			<div className="qlosr__footer-heading">
				<h1 className="gradient__text">Nöjda kunder leder till fler nöjda kunder</h1>
			</div>

			<div className="qlosr__footer-btn">
				<a target="_blank" rel="noreferrer" href="https://qlosr.se/">
					Qlosr.se <AiOutlineRight />
				</a>
			</div>

			<div className="qlosr__footer-supportme">
				<h4>
					Sidan kommer att vara fri från reklam för att hålla den så clean som möjligt. Alternativ Qlosr drivs
					enbart av mig på min fritid och jag kommer att försöka hålla den uppdaterad i den grad som tid ges.
				</h4>
				<h4>
					Om du uppskattar sidan kan du stödja den via swish på 0704608611 - Tack på förhand, Ludvig Björn{' '}
					<BiRocket />
				</h4>
				<h4 className="gradient__text" style={{ marginTop: 8, fontSize: 14 }}>
					<a
						href="https://www.avanza.se/aktier/om-aktien.html/743227/qlosr-group-b"
						target="_blank"
						rel="noreferrer"
					>
						Köp och HÅLL
					</a>
				</h4>
			</div>

			<div className="qlosr__footer-links">
				<div className="qlosr__footer-links_div">
					<h4>Kontakt</h4>
					<a href="https://linkedin.com/in/ludvigbjorn" target="_blank" rel="noreferrer">
						<p style={{ cursor: 'pointer' }}>
							<IoLogoLinkedin />
							LinkedIn
						</p>
					</a>
					<p>bslbjorn@gmail.com</p>
				</div>
			</div>

			{/*
				<div className="qlosr__footer-links">
				<div className="qlosr__footer-links_logo">
				<div>Qlosr</div>
					<p>Address</p>
					</div>

				<div className="qlosr__footer-links_div">
				<h4>Links</h4>
					<p>Overons</p>
					<p>Social Media</p>
					<p>Counters</p>
					<p>Contact</p>
				</div>

				<div className="qlosr__footer-links_div">
				<h4>Company</h4>
				<p>Overons</p>
				<p>Social Media</p>
					<p>Counters</p>
				</div>

				<div className="qlosr__footer-links_div">
					<h4>Get in touch</h4>
					<p>Overons</p>
					<p>Social Media</p>
					<p>Counters</p>
					<p>Contact</p>
					</div>
					</div>
					
				*/}
			<div className="qlosr__footer-copyright">
				<p>© 2021 Alternativ Qlosr</p>
			</div>
		</div>
	);
};

export default Footer;
