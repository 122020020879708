import React, { useState } from 'react';
import VideoDetail from '../../components/videoDetail/VideoDetail';
import VideoList from '../../components/videoList/VideoList';
import './whatQlosrFans.css';

// const featuresData = [
// 	{
// 		title: 'Vilka är Qlosr?',
// 		text: [
// 			'Qlosr Group AB (publ) är en helhetsleverantör av IT-lösningar vilket omfattar försäljning och leverans av paketerade prenumerationstjänster. Största delen av verksamheten bedrivs på den nordiska marknaden och består av paketerade IT-tjänster inom arbetsplats, nätverk och serverdrift. Bolagets tre primära målgrupper utgörs av små- och medelstora företag, offentlig sektor samt den privata skolsektorn med fokus på större nationella skolkoncerner.'
// 		]
// 	}
// ];

const WhatQlosrFans = () => {
	const [ selectedVideo, setSelectedVideo ] = useState({
		id: 16,
		title: 'Qlosr – Stora Aktiedagen Stockholm 15 mars 2023',
		url: 'wmBEvh2MA-A'
	});

	const myVideos = [
		{
			id: 16,
			title: 'Qlosr – Stora Aktiedagen Stockholm 15 mars 2023',
			url: 'wmBEvh2MA-A'
		},
		{
			id: 15,
			title: 'Qlosr Group | Livesänd rapportpresentation Q4 med VD Jonas Norberg och finanschef Urban Norberg',
			url: 'OOmpP7348u8'
		},
		{
			id: 14,
			title: 'Qlosr Group – Stora Aktiedagen Stockholm 28 november 2022',
			url: 'r6rI4R5WkTE'
		},
		{
			id: 13,
			title: 'Analyst Group intervjuar Qlosr Groups VD Jonas Norberg i samband med bolagets Q3-rapport år 2022.',
			url: 'MxlaF9YNd8w'
		},
		{
			id: 12,
			title: 'Qlosr Group | Livesänd rapportpresentation Q3 med VD Jonas Norberg och finanschef Urban Norberg',
			url: '_dtg6VqjcL0'
		},
		{
			id: 11,
			title: 'Livesänd rapportpresentation Q2 med VD Jonas Norberg och finanschef Urban Norberg',
			url: '0AxJaMTioeY'
		},
		{
			id: 10,
			title: 'Intervju med Qlosr Groups VD Jonas Norberg',
			url: 'OadyKbvpPiw'
		},
		{
			id: 9,
			title: 'Qlosr - ABGSC Investor Days May 23 2022',
			url: '-CKCZbHmxgQ'
		},
		{
			id: 8,
			title: 'Qlosr Group | Intervju med vd Jonas Norberg om bolaget och finansiella mål',
			url: 'NGqxYKWhQLQ'
		},
		{
			id: 7,
			title:
				'Analyst Group intervjuar Qlosr Groups VD Jonas Norberg i samband med bolagets bokslutskommuniké för år 2021',
			url: 'MAvhCyx31jk'
		},
		{ id: 6, title: 'Qlosr Group bolagspresentation 2022-01-05', url: '6imDrAe88wY' },
		{ id: 5, title: 'Qlosr | Vd Jonas Norberg om förvärven av Rg19 & SBL Data', url: '8FqE2nWjUBU' },
		{
			id: 4,
			title: 'Qlosr Group presenterar på Mangold Insights investerarfrukost 17 nov 2021',
			url: '2-fM83nC8nk'
		},
		{ id: 3, title: 'HemmaHos - Qlosr Group', url: 'MnX0rExDqYg' },
		{ id: 2, title: 'Intervju med VD Jonas Norberg på Qlosr', url: 'CZ74mJ0XYak' },

		{ id: 1, title: 'Qlosr Group AB notering på Nordic Growth Market', url: 'SVGHo__2OdY' }
	];

	const onVideoSelect = (video) => {
		setSelectedVideo(video);
	};

	return (
		<div className="qlosr__whatqlosr" id="intervjuer">
			<div className="qlosr__features-heading">
				<h1 className="gradient__text">Intervjuer</h1>
			</div>
			{/*
			<div className="qlosr__whatqlosr-feature">
			<Feature title={featuresData.title} text={featuresData.text} />
			</div>
		*/}
			{/*
			<div className="qlosr__whatqlosr-heading">
			<h1 className="gradient__text">Don't think about IT. We do.</h1>
			<p>Utforska</p>
			</div>
		*/}

			<VideoDetail video={selectedVideo} />
			<VideoList videos={myVideos} onVideoSelect={onVideoSelect} />

			{/*
				<div className="qlosr__whatqlosr-container">
				
				<Feature
				title="Qlosr to Business"
					text="För att uppnå effektivitet och möjliggöra utvecklingen av ditt företag behövs driftsäkra, skalbara och flexibla IT-lösningar som är anpassade efter just din verksamhet och de behov som den har. Vi tror på ett nära partnerskap där verksamhetsmålen sätter riktningen för vårt samarbete, oavsett om ni är ett litet eller medelstort bolag. Vi vill utvecklas tillsammans med er över tid och genom att tillsammans fokusera på att göra er verksamhet mer konkurrenskraftig har vi optimala förutsättningar för att leverera tjänster och lösningar som bidrar till måluppfyllnad! "
					/>
					<Feature
					title="Qlosr to Education"
					text="Hur sticker din verksamhet ut i mängden? Friskolor har idag en enorm konkurrens och jobbar hårt för att vara elevens första val. En väl fungerande IT-miljö där hantering av identiteter, kopplingar mot system och modern hårdvara är ett grundkrav idag. Vi har lång erfarenhet av att arbeta tillsammans med utbildningsverksamheter och via våra kompetenshöjande nätverk kan vi erbjuda mer än flexibla och driftsäkra IT-lösningar. Vi tror på att utvecklas ihop med våra kunder och vi ser fram emot att bli fler i vårt aktiva nätverk av friskolor där vi letar skalfördelar och erfarenhetsutbyten!"
				/>
				<Feature
					title="Qlosr Group AB notering på Nordic Growth Market"
					text={[
						'Qlosr Group är nu noterade på Nordic Growth Market. Qlosr är en nordisk helhetsleverantör av IT-lösningar för arbetsplatser, nätverk samt serverdrift och erbjuder prenumerationstjänster till företag och skolor.'
					]}
					/>
					</div>
					<iframe
					frameBorder="0"
					src="https://www.youtube.com/embed/SVGHo__2OdY"
					// src="https://youtu.be/SVGHo__2OdY"
					title="Video Player"
					width="200px"
					// height="20%"
					/>
				*/}
		</div>
	);
};

export default WhatQlosrFans;
